/* PROGRESSO/STEPS TEMPLATE 01 */

#homesteps_01 {
    background-color: var(--white);
    padding-top: 3rem;
    padding-bottom: 3rem;

    .step-column {
        position: relative;

        .step-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            background-color: transparent;
        }
        
        .step-container .step-circle {
            display: flex;
            justify-content: center;
            width: 90px;
            height: 90px;
            text-align: center;
            border: 2px solid var(--primary);
            border-radius: 50%;
            align-items: center;
            position: relative;
            background-color: transparent;
            transition: all .2s ease-in-out;
    
            .step-circle-content {
                font-weight: 700;
                color: var(--bs-dark);
                font-size: 1.6em;
                letter-spacing: -1px;
                transition: all .2s ease-in-out;
            }
        }
        
        .step-container .step-content {
            text-align: center;
            margin-top: 20px;
        
            .step-title {
                font-weight: 700;
                font-size: 1.2rem;
            }
            .step-text {
                font-size: 0.875rem;
            }
        }

        .step-container:hover {
            .step-circle {
                background-color: var(--primary);
            }
    
            .step-circle-content {
                color: var(--white);
            }
        }
        

    }

    @media (min-width: 767px) { 
        .step-column::before {
            content: '';
            height: 2px;
            display: block;
            background-color: var(--primary);
            /*background-color: #ededed;*/
            position: absolute;
            width: 50%;
            top: 45px;
            left: 75%;
        }
    }

    @media (min-width: 992px) {
        .step-column:last-child::before {
            display: none;
        }
    }

    @media (max-width: 992px) { 
        .step-column:nth-child(even)::before {
            display: none;
        }
    }
    
}

/* END PROGRESSO/STEPS */