 /* TÍTULOS TEMPLATE 03 */

 .page-03 .title-properties .titulo-pp{
    position: relative;
    text-transform: unset;
}

.page-03 #homemote_01 .title-properties .titulo-pp {
    color: var(--secondary);
}

.page-03 .title-properties .subtitulo-pp{
    font-size: 1.2rem;
    text-transform: unset;
}

.page-03 #homemote_01 .title-properties .subtitulo-pp {
    color: var(--white);
}

.page-03 .title-properties .titulo-pp:before,
.page-03 .title-properties .titulo-pp:after {
    content: "";
    width: 50px;
    height: 2px;
    background: var(--primary);
    display: inline-block;
}

.page-03 #homemote_01 .title-properties .titulo-pp:before,
.page-03 #homemote_01 .title-properties .titulo-pp:after { 
    background: var(--white);
}

.page-03 .title-properties .titulo-pp:before {
    margin: 0 15px 10px 0;
    }

.page-03 .title-properties .titulo-pp:after {
    margin: 0 0 10px 15px;
}

 /* END TÍTULOS TEMPLATE 03 */

#homeform_03 {
    padding-top: 3rem;
    padding-bottom: 3rem;
    background-color: var(--primary);

    /*.banner-form{
        position: relative;
        height: 100%;

        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
    }*/

    .overlay-banner {
        background: rgba(0, 0, 0, 0.3);
        width: 100%;
        height: 100%;
         /*background: rgba(211, 215, 221, 0.6);
        background: rgb(140 111 41 / 75%);*/
    }
}