
/* CALL TO ACTION LANDING PAGE TEMPLATE 01*/

#homecalltoaction_01{
    padding-top: 5rem;
    padding-bottom: 5rem;
    background-color: var(--primary);

    .cta-content{
        color: var(--white);
        text-align: center;

        .cta-title {
            color: var(--white);
            font-size: 2.2rem;
            font-weight: 700;
            margin-bottom: 1rem;
        }

        .cta-description {
            font-size: 1rem;
            white-space: pre-wrap;
        }

        a {
            margin-top: 1rem;
        }
    }
}

/* END CALL TO ACTION */
