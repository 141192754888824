/* PRICING TEMPLATE 02 */

#homepricing_02{
    padding-top: 3rem;
    padding-bottom: 3rem;

    .pricing-highlight .card .card-header{
        background-color: var(--secondary);
    }
    .pricing-highlight .card .btn-pricing {
        background-color: var(--secondary);
        border: 1px solid var(--secondary);
    }

    .card{
        border: 1px solid rgba(0,0,0,.05);
        box-shadow: 0 1px 2px rgba(0,0,0,0.06), 0 1px 2px rgba(0,0,0,0.08);
        transition: all .3s ease-in-out;
        overflow: hidden;
        text-align: center;
        /*transition: all 0.3s cubic-bezier(.25,.8,.25,1);
        background-color: var(--gray-light);*/

        .card-header {
            padding: 1rem;
            background-color: var(--bs-gray-600);
            color: var(--white);
            text-align: center;

            .pricing-title {
                font-size: 1.1rem;
                font-weight: 700;
                text-transform: uppercase;
                line-height: 1.35;
                margin-bottom: 0;
            }
        }

        .pricing-value {
            /*display: inline-block;
            padding-right: 15px;*/
            font-size: 2rem;
            font-weight: 700;
            position: relative;

            .price-symbol {
                font-size: 0.875rem;
                vertical-align: top;
                /*position: absolute;
                top: 5px;
                right: 0;*/
            }
            .price-type {
                font-size: 0.8rem;
            }
        }

        .pricing-abstract {
            font-size: 0.875rem;
            margin-bottom: 1rem;
        }

        .pricing-features ul {
            padding-left: 0;
            list-style: none;
            text-align: center;
        
            li {
                border-bottom: 1px solid #ededed;
                line-height: 2.9;
                list-style: none;
                font-size: 0.875rem;
            }
        }

        .btn-pricing {
            font-size: 1rem;
            font-weight: 600;
            background-color: var(--bs-gray-600);
            border: 1px solid var(--bs-gray-600);
            color: var(--white);
            border-radius: 30px;
            padding: 0.5rem 1.125rem;
            -webkit-box-shadow: 0 1px 2px rgba(0,0,0,0.06), 0 1px 2px rgba(0,0,0,0.08);
            box-shadow: 0 1px 2px rgba(0,0,0,0.06), 0 1px 2px rgba(0,0,0,0.08);
        }

        .btn-pricing:hover, .btn-pricing:active,
        .btn-pricing:focus, .btn-pricing.focus{
            background-color: var(--primary) !important;
            border-color: var(--primary) !important;
        }

        &:hover {
            /*box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);*/
            box-shadow: 0 3px 6px rgba(0,0,0,0.06), 0 3px 6px rgba(0,0,0,0.08);
            transform: translateY(-10px);
        }
    }
}

/* PRICING */