/* CONTENTS LANDING PAGE TEMPLATE 01 */
.homefloatingcontent_01{
    padding-top: 3rem;
    padding-bottom: 3rem;
    background-color: var(--gray-light);

    .div-container{
        /*color: var(--white);*/

        /*.content-title {
            color: var(--secondary);
            font-size: 2.2rem;
            font-weight: 700;
            margin-bottom: 1rem;
        }*/

        .content-description {
            font-size: 1rem;
        }

        a {
            margin-top: 1rem;
        }
    }
}