/* PRICING TEMPLATE 01 */

#homepricing_01{
    padding-top: 3rem;
    padding-bottom: 3rem;
    background-color: var(--gray-light);

    .pricing-highlight .card{
        /*border-top-color: var(--secondary);*/
        border: 3px solid var(--secondary);

        .card-body {

            .pricing-value {
                color: var(--secondary);
            }
            .btn-pricing {
                background-color: var(--secondary);
                border: 1px solid var(--secondary);
                color: var(--white);
            }
        }
    }

    .card{
        border: 1px solid rgba(0,0,0,.05);
        box-shadow: 0 1px 2px rgba(0,0,0,0.06), 0 1px 2px rgba(0,0,0,0.08);
        transition: all .3s ease-in-out;
        overflow: hidden;
       /*border-top: 5px solid var(--white);
        text-align: center;
        transition: all 0.3s cubic-bezier(.25,.8,.25,1);
        background-color: var(--gray-light);*/

        .card-body {
            padding: 2rem;

            .pricing-title {
                font-size: 1rem;
                font-weight: 700;
                text-transform: uppercase;
                color: var(--primary);
                line-height: 1.35;
                margin-bottom: 10px;
            }

            .pricing-value {
                /*display: inline-block;
                padding-right: 15px;*/
                font-size: 2rem;
                font-weight: 700;
                position: relative;

                .price-symbol {
                    font-size: 0.875rem;
                    vertical-align: top;
                    /*position: absolute;
                    top: 5px;
                    right: 0;*/
                }
                .price-type {
                    font-size: 0.8rem;
                }
            }

            .pricing-abstract {
                font-size: 0.875rem;
                margin-bottom: 1rem;
            }

            .pricing-features ul {
                padding-left: 0;
                list-style: none;
                /*text-align: center;*/
            
                li {
                    border-bottom: 1px solid #ededed;
                    line-height: 2.9;
                    list-style: none;
                    font-size: 0.875rem;
                }
            }

            .btn-pricing {
                font-size: 1rem;
                font-weight: 600;
                background-color: var(--white);
                border: 1px solid var(--primary);
                color: var(--primary);
                border-radius: 30px;
                padding: 0.5rem 1.125rem;
                -webkit-box-shadow: 0 1px 2px rgba(0,0,0,0.06), 0 1px 2px rgba(0,0,0,0.08);
                box-shadow: 0 1px 2px rgba(12, 11, 11, 0.06), 0 1px 2px rgba(0,0,0,0.08);
            }

            .btn-pricing:hover, .btn-pricing:active,
            .btn-pricing:focus, .btn-pricing.focus{
                background-color: var(--primary) !important;
                border-color: var(--primary) !important;
                color: var(--white);
            }

        }

        &:hover {
            /*box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);*/
            box-shadow: 0 3px 6px rgba(0,0,0,0.06), 0 3px 6px rgba(0,0,0,0.08);
            transform: translateY(-10px);
        }
    }
}

/* PRICING */