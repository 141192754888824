footer {
	background-color: var(--secondary);
	color: var(--white);
	font-size: 0.875rem;
	padding-top: 3rem;
	/*margin-top: 170px;*/
    /*width: 100%;
    bottom: 0;
	position: absolute;*/
	
}
footer a {
	color: var(--white);
}

footer a:hover {
	color: var(--primary);
}

footer h5 {
	font-weight: 700;
	font-size: 1rem;
	color: var(--white);
}
footer h6 {
    font-size: 0.875rem;
}

footer .social-media i {
    font-size: 1.5rem;
}

footer .current_bottom{
	color: var(--primary);
    text-decoration: underline;
}

footer .social-media img:hover,
footer .social-media i:hover {
	filter: drop-shadow(0px 0px 20px var(--primary));
}

/*footer span.logosfooter {
	display: flex;
	align-items: flex-start;
	justify-content: space-evenly;
}*/
footer .copyright-info {
    padding: 0.75rem 0;
	font-size: 12px;
	text-align: center;
	background-color: color-mix(in srgb, var(--secondary), white 5%);
}