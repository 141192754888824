#homemote_01 {
    background-color: var(--primary);
    color: #FFFFFF;
    padding-top: 3rem;
    padding-bottom: 3rem;

    .text-columns {
        color: var(--white);
        column-count: 2;
        column-gap: 30px;
        orphans: 3;
    }
}

@media only screen and (max-width: 767px) {
     #homemote_01 .text-columns {
        text-align: center;
        column-count: 1;
    }

}