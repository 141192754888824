/* MARCAS TEMPLATE 03 */

#homebrands_03 {
    background-color: var(--bs-gray-300);
    padding-top: 3rem;
    padding-bottom: 3rem;
}

#homebrands_03 .brand-wrapper {
    
    border-top: 6px solid color-mix(in srgb, var(--black), transparent 85%);
    border-left: 6px solid color-mix(in srgb, var(--black), transparent 85%);
}

#homebrands_03 .brand-container {
    background-color: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 6px solid color-mix(in srgb, var(--black), transparent 75%);
    border-bottom: 6px solid color-mix(in srgb, var(--black), transparent 75%);
    overflow: hidden;

    img {
        padding: 50px;
        max-width: 80%;
        transition: 0.3s;
    }

    &:hover img {
        transform: scale(1.1);
    }

    @media only screen and (max-width: 767px) { 
        img {
            padding: 0;
            max-width: 100%;
        }
    }

}