/* PARCEIROS */

#homepartners_01 {
    padding-top: 3rem;
    padding-bottom: 3rem;
}

#homepartners_01 .card-body{
    text-align: center;
    background-color: var(--gray-light);
}

#homepartners_01 .card:hover .partner-title {
    color: var(--primary);
}

/* PARCEIROS */