/* SERVIÇOS LANDING PAGE TEMPLATE 01*/

#homeservices_01{
    padding-top: 3rem;
    padding-bottom: 3rem;
}

#homeservices_01 .service-container {
    border-radius: 0.375rem;
    overflow: hidden;
    border: 1px solid rgba(0,0,0,.05);
    box-shadow: 0 1px 2px rgba(0,0,0,0.06), 0 1px 2px rgba(0,0,0,0.08);
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;

    &:hover {
        box-shadow: 0 3px 6px rgba(0,0,0,0.06), 0 3px 6px rgba(0,0,0,0.08);
        /*transform: translate3d(0, -10px, 0);
        box-shadow: 0px 15px 20px rgba(var(--primary-rgb), 0.4);
        box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);*/
    }

    .service-content {
        padding: 1rem;
        background-color: var(--gray-light);
    
        .service-title {
            color: var(--primary);
            font-size: 1.1rem;
            font-weight: 700;
            text-transform: uppercase;
            line-height: 1.35;
            transition: ease-in-out 0.3s;
            margin-bottom: 10px;
        }
    
        .service-description {
            /*display: -webkit-box;
            text-overflow: ellipsis;
            -webkit-line-clamp: 8;
            line-clamp: 8;
            -webkit-box-orient: vertical;
            overflow: hidden;*/
        
            p {
                color: var(--body);
                font-size: 0.875rem;
                margin-bottom: 0;
            }
        }
    }
}


/* END SERVIÇOS */
