.grid_container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 2vw;
    padding: 0 30px;
    align-items: baseline;
}

.grid_container>div {
    width: 40px;
    height: 50px;
    font-size: 30px;
    padding: .5em;
    text-align: center;
}


.grid_container .grid_item {
    max-width: 250px;
    max-height: 300px;
}
.grid_container.open .grid_item {
    max-width: 200px;
    max-height: 250px;
}

.grid_container .grid_item img {
    border-radius: 15px;
}


.label_nome_produto {
    height: 3em;
    color: var(--black);

}

.label_price {
    color: var(--primary);
    font-size: 17px;
}

.label_black {
    color: var(--black);
}

.btn_price {
    color: var(--gray);
    font-size: 17px;
}

.label_from {
    color: var(--gray);
}

.container_principal_flex {
    display: 'flex';
    flex: 0.9;
    flex-direction: row;
    width: 90%;

}

.container_principal_flex_depois_de_carrinho_aberto {
    display: 'flex';
    flex: 0.8;
    flex-direction: row;
}

.container_principal {
    min-height: 100vh;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(420px, 1fr));
    grid-gap: 5vw;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 3%;


}

.container_novo_carrinho {
    display: 'flex';
    flex: 0.2;
    flex-direction: column;
    background-color: 'green';
}

.container_esquerda {
    display: flex;
    flex: 0.5;
    flex-direction: column;

}

.container_direita {
    display: flex;
    flex: 0.5;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10%;
    justify-self: center;



}

.container_slider {
    display: flex;
    flex: 0.2;
    flex-direction: column;
}

.container_info_prod {
    display: flex;
    flex: 0.8;
    flex-direction: column;
}

.container_min_e_preco {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

}

.container_titulo_calendario {
    height: calc(50vh - 101px);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10%;
    justify-self: center;
    justify-content: space-between;

}

.container_lista_horario_manha_e_tarde {

    display: flex;
    flex: 1;
    flex-direction: row;
    min-width: 100%;

}

.container_manha {
    display: flex;
    flex: 0.5;
    flex-direction: column;

}

.container_tarde {
    display: flex;
    flex: 0.5;
    flex-direction: column;
    margin-left: 3%;


}

.mymodal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid #ccc;
    background: #fff;
    overflow: auto;
    border-radius: 2rem;
    outline: none;
    padding: 20px;
}

.myoverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 10;
}

.container_input_utilizador {
    margin-top: 20%;
    padding-left: 10%;
    min-width: 90%;
    display: flex;
    flex-direction: column;

}

.container_input_password {
    padding-left: 10%;
    min-width: 90%;
    margin-top: 10%;
    display: flex;
    flex-direction: column;
}

.container_img {
    margin-top: 20%;
    min-width: 100%;
    display: flex;
    justify-content: center;

}

.input_email {
    background-color: rgba(0, 0, 0, 0.1);
    border-style: none;
    line-height: 30px;



}

.input_password {
    background-color: rgba(0, 0, 0, 0.1);
    border-style: none;
    line-height: 30px;

    min-width: 90%;
    margin-bottom: 20%;

}

.button_recuperar_e_registar_conta {
    background-color: white;
    border: none;

}

.div1_totalmente_aberto {
    width: 100%;

}

.div1_parcialmente_aberto {
    width: 80%;

}

/* @keyframes animation_div1 {
    0% {
        width: 100%;
    }
    100% {
        width: 80%;
    }
} */



.div-cart {
    /* width: 25%;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0; */
    background-color: var(--white);

    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);

    -webkit-transition: all 0.75s ease-out;
    -moz-transition: all 0.75s ease-out;
    -o-transition: all 0.75s ease-out;
    transition: all 0.75s ease-out;

    min-width: 400px;
}

.div-cart.closed {
    right: -25%;
}

.div2_fechado {
    width: 0%;

}

.div2_aberto {
    width: 20%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.overlay-store-img {
    background: rgb(140 111 41 / 75%);
}

@media only screen and (max-width: 600px) {
    .div2_aberto {
        width: 30%;
        z-index: 999;
        background-color: purple;
    }


}

@keyframes animation_div2 {
    0% {
        width: 0%;
    }

    /*  25% {
        width: 2%;
    }

    50% {
        width: 5%;
    }

    75% {
        width: 7%;
    }
 */
    100% {
        width: 20%;
    }
}