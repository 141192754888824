/* PARCEIROS WELCOME TEMPLATE 03 */

#homepartners_03 {
    background-color: var(--bs-gray-300);
}

#homepartners_03 .card {

    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0,0,0,.05);
    transition: 0.5s;

    /*.row {
        background-color: rgba(240, 238, 255, 0.5);
    }*/

    img {
        border-radius: 0.375rem;
    }

    &:hover {
        transform: translateY(-10px);
        box-shadow: 0px 2px 15px rgba(var(--primary-rgb), 0.7);
    }
}

#homepartners_03 .card-body{
    text-align: left;

    .partner-title {
        font-size: 1.2rem;
        color: var(--primary);
        font-weight: 700;
        line-height: 1.35;
        margin-bottom: 0.5rem;
    }

    .partner-text {
        display: -webkit-box;
        text-overflow: ellipsis;
        -webkit-line-clamp: 3;
        line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
    
    }

    .partner-text p{
        font-size: 0.875rem;
        margin-bottom: 0;
    }

    .partner-link{
        font-size: 0.875rem;
    }
    
}