/* PROGRESSO/STEPS TEMPLATE 02 */

#homesteps_02 {
    background-color: var(--white);
    padding-top: 3rem;
    padding-bottom: 3rem;

    .step-column {
        position: relative;

        .step-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            background-color: transparent;
        }
        
        .step-container .step-circle {
            display: flex;
            justify-content: center;
            width: 90px;
            height: 90px;
            text-align: center;
            /*border: 2px solid var(--primary);*/
            border: 3px dotted #e4e4e4;
            border-radius: 50%;
            align-items: center;
            position: relative;
            background-color: transparent;
            transition: all .2s ease-in-out;
    
            .step-circle-content {
                font-weight: 700;
                color: var(--primary);
                font-size: 1.6em;
                letter-spacing: -1px;
                transition: all .2s ease-in-out;
            }
        }
        
        .step-container .step-content {
            text-align: center;
            margin-top: 20px;
        
            .step-title {
                font-weight: 700;
                font-size: 1.2rem;
            }
            .step-text {
                font-size: 0.875rem;
            }
        }
        
        .step-container:hover {
            .step-circle {
                background-color: var(--primary);
            }
    
            .step-circle-content {
                color: var(--white);
            }
        }
        

    }

    @media (min-width: 767px) { 
        .step-column::after {
            content: "";
            display: block;
            width: 10px;
            height: 10px;
            border-top: 8px solid transparent;
            -moz-border-radius: 2px;
            -ms-border-radius: 2px;
            -webkit-border-radius: 2px;
            -o-border-radius: 2px;
            border-bottom: 8px solid transparent;
            border-radius: 2px;
            position: absolute;
            right: -7px;
            top: 50px;
            text-align: center;
            border-left: 8px solid rgba(var(--primary-rgb), 0.8);
        }
    }

    @media (min-width: 992px) {
        .step-column:last-child::after {
            display: none;
        }
    }

    @media (max-width: 992px) { 
        .step-column:nth-child(even)::after {
            display: none;
        }
    }
    
}

/* END PROGRESSO/STEPS */