/* SERVIÇOS LANDING PAGE TEMPLATE 05 */

#homeservices_05{
    padding-top: 3rem;
    padding-bottom: 3rem;
}

#homeservices_05 .service-container {
    text-align: center;
    border-radius: 0.375rem;
    box-shadow: 0 1px 2px rgba(0,0,0,0.06), 0 1px 2px rgba(0,0,0,0.08);
    /*overflow: hidden;
    border: 1px solid rgba(0,0,0,.05);
    box-shadow: 0 1px 2px rgba(0,0,0,0.06), 0 1px 2px rgba(0,0,0,0.08);
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;*/

    display: block;
    border: 3px solid rgba(0, 0, 0, 0.05);
    background-color: var(--white);
    padding: 20px;
    position: relative;

    :before {
        display: block;
        content: "";
        width: 9%;
        height: 17%;
        position: absolute;
        bottom: -3px;
        right: -3px;
        border-bottom: 3px solid var(--bs-gray-400);
        border-right: 3px solid var(--bs-gray-400);
        transition: all 0.5s ease 0s;
        -moz-transition: all 0.5s ease 0s;
        -ms-transition: all 0.5s ease 0s;
        -webkit-transition: all 0.5s ease 0s;
        -o-transition: all 0.5s ease 0s;
    }

    :after {
        display: block;
        content: "";
        width: 9%;
        height: 17%;
        position: absolute;
        top: -3px;
        left: -3px;
        border-top: 3px solid var(--bs-gray-400);
        border-left: 3px solid var(--bs-gray-400);
        transition: all 0.5s ease 0s;
        -moz-transition: all 0.5s ease 0s;
        -ms-transition: all 0.5s ease 0s;
        -webkit-transition: all 0.5s ease 0s;
        -o-transition: all 0.5s ease 0s;
    }

    &:hover {
        opacity: 1;
        border-color: var(--bs-gray-200);

        box-shadow: 0 3px 6px rgba(0,0,0,0.06), 0 3px 6px rgba(0,0,0,0.08);
        /*box-shadow: 0px 15px 20px rgba(var(--primary-rgb), 0.4);
        box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);*/

        :before,
        :after {
            width: 95%;
            height: 90%;
        }
    }

    .service-image {
        padding-bottom: 15px;
    }

    .service-content {
        /*padding: 1rem;
        background-color: var(--gray-light);*/
    
        .service-title {
            color: var(--primary);
            font-size: 1.1rem;
            font-weight: 700;
            text-transform: uppercase;
            line-height: 1.35;
            transition: ease-in-out 0.3s;
            margin-bottom: 10px;
        }
    
        .service-description {
            /*display: -webkit-box;
            text-overflow: ellipsis;
            -webkit-line-clamp: 8;
            line-clamp: 8;
            -webkit-box-orient: vertical;
            overflow: hidden;*/
        
            p {
                color: var(--body);
                font-size: 0.875rem;
                margin-bottom: 0;
            }
        }
    }
}


/* END SERVIÇOS */
