/* SWIPER */

    /*.swiper,
    .swiper-container {
        width: 100%;
        height: 100%;
    }*/

    .swiper-button-next{
        /*background-image: none;
        right: -20px;
        left: auto;*/
        right: 30px;
        outline: none;
        color: var(--primary) !important;
    }
    .swiper-button-prev {
        /*background-image: none;
        left: -20px;
        right: auto;*/
        left: 30px;
        outline: none;
        color: var(--primary) !important;
    }

    .swiper-pagination-bullet {
        width: 1rem !important;
        height: 1rem !important;
        background: var(--white) !important;
        opacity: .7 !important;
    }

    #news .swiper-pagination-bullet,
    #programas .swiper-pagination-bullet,
    #parcerias .swiper-pagination-bullet {
        background: var(--secondary) !important;
    }

    .swiper-pagination-bullet-active,
    #parcerias .swiper-pagination-bullet-active {
        background: var(--primary) !important;
        opacity: 1 !important;
        outline: none;
    }

    /* TESTEMUNHOS */
        /*#opinion .swiper-pagination-bullet-active{
            background-color: var(--body) !important;
            margin: 0 5px;
        }
        #opinion .swiper-pagination-bullet{
            margin: 0 2px;
        }*/

    /* end TESTEMUNHOS */

    .swiper-pagination-cartaz {
        position: absolute;
        text-align: center;
        transition: .3s opacity;
        transform: translate3d(0,0,0);
        z-index: 10;
    }

    .swiper-pagination-programas,
    .swiper-pagination-parcerias,
    .swiper-pagination-testemunhos {
        position: relative;
        text-align: center;
        transition: .3s opacity;
        transform: translate3d(0,0,0);
        z-index: 10;
        bottom: 0 !important;
    }

    #slider .swiper-slide{
        height: calc(100vh - 120px);
    }
    @media screen and (max-width: 767px) {
        #slider .swiper-slide{
            height: calc(80vh - 70px);
        }

        #slider .slide-image {
            background-position: left;
        }
    }

    #slider .swiper-button-next{
        top: 0;
        right: 0;
        height: 100%;
        width: 8%;
        margin-top: 0;
    }

    #slider .swiper-button-prev{
        top: 0;
        left: 0;
        height: 100%;
        width: 8%;
        margin-top: 0;
    }

    #slider .swiper-button-prev:after,
    #slider .swiper-button-next:after {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }

/* END SWIPER */

/*CARTAZ*/

    .caption_top_center {
        position: absolute;
        top: 25%;
        right: 50%;
        left: 50%;
        width: 50%;
        text-align: center;
        padding: 0;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

    .caption_top_left {
        position: absolute;
        top: 25%;
        left: 0;
        width: 50%;
        text-align: left;
        -webkit-transform: translate(30%, -50%);
        -moz-transform: translate(30%, -50%);
        -ms-transform: translate(30%, -50%);
        -o-transform: translate(30%, -50%);
        transform: translate(30%, -50%);
    }

    .caption_top_right {
        position: absolute;
        top: 25%;
        left: 0%;
        width: 50%;
        text-align: right;
        -webkit-transform: translate(70%, -50%);
        -moz-transform: translate(70%, -50%);
        -ms-transform: translate(70%, -50%);
        -o-transform: translate(70%, -50%);
        transform: translate(70%, -50%);
    }

    .caption_bottom_left {
        position: absolute;
        /*top: 50%;*/
        bottom: 35px;
        left: 0;
        width: 50%;
        text-align: left;
        -webkit-transform: translate(30%, -50%);
        -moz-transform: translate(30%, -50%);
        -ms-transform: translate(30%, -50%);
        -o-transform: translate(30%, -50%);
        transform: translate(30%, -50%);
    }

    .caption_bottom_right {
        position: absolute;
        bottom: 35px;
        left: 0%;
        width: 50%;
        text-align: right;
        -webkit-transform: translate(70%, -50%);
        -moz-transform: translate(70%, -50%);
        -ms-transform: translate(70%, -50%);
        -o-transform: translate(70%, -50%);
        transform: translate(70%, -50%);
    }

    .caption_bottom_center {
        position: absolute;
        right: auto;
        bottom: 35px;
        left: 50%;
        width: 50%;
        text-align: center;
        padding: 0;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

    .caption_middle_left {
        position: absolute;
        top: 50%;
        bottom: auto;
        left: 0%;
        width: 50%;
        text-align: left;
        -webkit-transform: translate(30%, -50%);
        -moz-transform: translate(30%, -50%);
        -ms-transform: translate(30%, -50%);
        -o-transform: translate(30%, -50%);
        transform: translate(30%, -50%);
    }

    .caption_middle_right {
        position: absolute;
        top: 50%;
        bottom: auto;
        left: 0%;
        width: 50%;
        text-align: right;
        -webkit-transform: translate(70%, -50%);
        -moz-transform: translate(70%, -50%);
        -ms-transform: translate(70%, -50%);
        -o-transform: translate(70%, -50%);
        transform: translate(70%, -50%);
    }

    .caption_middle_center {
        position: absolute;
        top: 50%;
        right: auto;
        bottom: auto;
        left: 50%;
        width: 50%;
        text-align: center;
        padding: 0;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

    /*.slide {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        text-align: center;
        font-size: 18px;
        background: #fff;
        overflow: hidden;
    }*/

    /*.swiper-pagination{
        bottom: 0;
        right: 0;
        left: 0;
    }*/

    .slide-image {
        /*position: absolute;*/
        background-position: 50% 50%;

        /*width: 110% !important;
        left: -5% !important;
        border-bottom-left-radius: 35%;
        border-bottom-right-radius: 35%;*/
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
    }


    /*.slide-title {
        font-size: 3vw;
        font-weight: 900;
        line-height: 1.3;
        color: var(--white);
        max-width: 50%;
        white-space: normal;
        word-break: break-word;
        text-transform: none;
        text-shadow: rgb(0 0 0 / 40%) 1px 1px 1px;
        z-index: 100;
    }*/

    .cartaz-titulo {
        color: var(--white);
        font-size: 3.5rem;
        font-weight: 700;
        line-height: 1em;
        /* text-transform: uppercase;
        text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5), 0px 0px 20px rgba(255, 255, 255, 0.5);*/
        text-shadow: 0 0.3rem 0.9rem rgb(0 0 0 / 40%);
        letter-spacing: 1.30469px;
    }

    .cartaz-subtitulo {
        color: var(--white);
        font-size: 1.8rem;
        line-height: 1.25;
        font-weight: 700;
        margin: 0;
        /*text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5), 0px 0px 20px #FFFFFF;*/
        text-shadow: 0 0.3rem 0.9rem rgb(0 0 0 / 40%);
    }

    /* Animation delays */

    .swiper-cartaz .swiper-slide-active h2,
    .swiper-cartaz .swiper-slide-active h3{
		animation-name: fadeIn;
		animation-duration: 2s;
		animation-fill-mode: both;
	}

    .swiper-cartaz .swiper-slide-active h2 {
		-webkit-animation-delay: 2s;
		animation-delay: 2s;
	}

	.swiper-cartaz .swiper-slide-active h3 {
		-webkit-animation-delay: 3s;
		animation-delay: 3s;
	}

	/*.swiper-cartaz .swiper-slide-active h3:first-child {
		-webkit-animation-delay: 2s;
		animation-delay: 2s;
	}

	.swiper-cartaz .swiper-slide-active h3:nth-child(2) {
		-webkit-animation-delay: 3s;
		animation-delay: 3s;
	}*/

    .swiper-cartaz .swiper-slide-active a {
		animation-name: fadeIn;
		animation-duration: 2s;
		animation-fill-mode: both;
		-webkit-animation-delay: 4s;
		animation-delay: 4s;
	}
    /* END Animation delays */

    .btn-cartaz {
        font-size: 0.875rem;
        font-weight: 700;
        color: var(--white) !important;
        border: 1px solid var(--white) !important;
        padding: 0.5rem 1.5rem;
        border-radius: 0;
        margin-top: 25px;
        background-color: var(--secondary);
        /*background-color: rgb(255 255 255 / 10%) !important;
        border-color: var(--white) !important;
        -webkit-box-shadow: 0 0 0 10px transparent !important;
        -moz-box-shadow: 0 0 0 10px transparent !important;
        box-shadow: 0 0 0 10px transparent !important;*/
    }
    .btn-cartaz:hover {
        color: var(--white) !important;
        border: 1px solid var(--white) !important;
        background-color: var(--primary);
        /*background-color: rgb(255 255 255 / 100%) !important;*/
    }

    @media screen and (max-width: 992px) {
        .cartaz-titulo {
            font-size: 4vh;
        }
    }
    @media screen and (max-width: 767px) {
        /*.cartaz-titulo {
            font-size: 3vh;
        }*/

        .cartaz-subtitulo { 
            font-size: 	18px;
        }

        .caption_top_center,
        .caption_top_left,
        .caption_top_right,
        .caption_middle_center,
        .caption_middle_left,
        .caption_middle_right,
        .caption_bottom_center,
        .caption_bottom_left,
        .caption_bottom_right {
            text-align: center;
            position: absolute;
            top: 50%;
            right: auto;
            bottom: auto;
            left: 50%;
            width: 85%;
            text-align: center;
            padding: 0;
            -webkit-transform: translate(-50%, -50%);
            -moz-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            -o-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
        }

        /*.swiper-button-next,
        .swiper-button-prev { 
            display: none !important;;
        }*/
    }

    /* BUTTON SCROLL SLIDER */
    .demo {
        position: absolute;
        bottom: 20px;
        left: 50%;
        z-index: 2;
        display: inline-block;
        -webkit-transform: translate(0, -50%);
        transform: translate(0, -50%);
        color: var(--cinza);
        text-decoration: none;
        transition: opacity .3s;
    }
    #slider-btn {
        padding-top: 60px;
    }
    #slider-btn span {
        position: absolute;
        top: 0;
        left: 50%;
        width: 30px;
        height: 50px;
        margin-left: -15px;
        border: 2px solid var(--white);
        border-radius: 50px;
        box-sizing: border-box;
    }
    #slider-btn span::before {
        position: absolute;
        top: 10px;
        left: 50%;
        content: '';
        width: 6px;
        height: 6px;
        margin-left: -3px;
        background-color: var(--white);
        border-radius: 100%;
        -webkit-animation: sdb10 2s infinite;
        animation: sdb10 2s infinite;
        box-sizing: border-box;
    }

    @-webkit-keyframes sdb10 {
        0% {
            -webkit-transform: translate(0, 0);
            opacity: 0;
        }
        40% {
            opacity: 1;
        }
        80% {
            -webkit-transform: translate(0, 20px);
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }
    @keyframes sdb10 {
        0% {
            transform: translate(0, 0);
            opacity: 0;
        }
        40% {
            opacity: 1;
        }
        80% {
            transform: translate(0, 20px);
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

/*end CARTAZ*/