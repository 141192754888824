
/* FAQS LANDING PAGE TEMPLATE 01*/

#homefaqs_01{
    padding-top: 3rem;
    padding-bottom: 3rem;

    .accordion-item {
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.06), 0 1px 2px rgba(0, 0, 0, 0.08);
        transition: all 0.3s cubic-bezier(.25,.8,.25,1);
        margin-bottom: 1rem;
        border-left: 3px solid var(--primary);
    
        /*box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
        box-shadow: 0px 15px 20px rgba(var(--primary-rgb), 0.4);
        box-shadow: 0 3px 6px rgba(0,0,0,0.06), 0 3px 6px rgba(0,0,0,0.08);*/
    
        .accordion-button {
            background-color: var(--gray-light);
            /*background-color: rgba(0, 0, 0, 0.03);*/
        }
    
        .faq-title{
            font-size: 1rem;
            font-weight: 700;
            line-height: 1.35;
            color: var(--body);
        }
    
        .faq-description {
            color: var(--body);
            font-size: 0.875rem;
        }
    
        &:hover .faq-title {
            color: var(--primary);
        }
        
    }
}


/* END FAQS */
