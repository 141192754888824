/* MARCAS TEMPLATE 01 */

#homebrands_01 {
    padding-top: 3rem;
    padding-bottom: 3rem;
}

#homebrands_01 .card {
    border: 1px solid rgba(0, 0, 0, .05);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.06), 0 1px 2px rgba(0, 0, 0, 0.08);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    overflow: hidden;
}
#homebrands_01 .card:hover {
    /*box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);*/
    box-shadow: 0 3px 6px rgba(0,0,0,0.06), 0 3px 6px rgba(0,0,0,0.08);
}