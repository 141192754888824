/* NOTÍCIAS WELCOME TEMPLATE 02*/

#homenews_02 {
    background-color: var(--gray-light);
    padding-top: 3rem;
    padding-bottom: 3rem;
}

#homenews_02 .article-container{
    position: relative;
    display: flex;
    flex-direction: column;

    .article-image {
        overflow: hidden;
        position: relative;
        border-radius: 0.375rem;
        img {
            transform: scale(1);
            transition: all .3s ease-in-out;
        }
    }

    .article-body {
        padding-top: 15px;

        .timestamp {
            font-size: 0.75rem;
            color: var(--bs-secondary-color);
            margin-bottom: 0.5rem;
            position: relative;

            &::before {
                content: "";
                width: 40px;
                height: 1px;
                display: inline-block;
                background: var(--primary);
                margin: 4px 10px;
                margin-left: 0;
            }
        }
        
        .article-title {
            color: var(--secondary);
            font-size: 1.1rem;
            font-weight: 700;
            margin-bottom: 0.75rem;
            line-height: 1.35;
            transition: 0.3s;
        }
        .article-text {
            display: -webkit-box;
            text-overflow: ellipsis;
            -webkit-line-clamp: 4;
            line-clamp: 4;
            -webkit-box-orient: vertical;
            overflow: hidden;
            color: var(--body);
            font-size: 0.875rem;
        }
        .article-text p{
            color: var(--body);
            font-size: 0.875rem;
            margin-bottom: 0;
        }
        .article-link {
            text-decoration: none;
            font-weight: bold;
            color: var(--primary);
        }
    }

    &:hover {
        .article-image img {
            transform: scale(1.1);
        }
        .article-body {
            .article-title {
                color: var(--primary);
            }
            /*.article-link { 
                color: var(--primary);
            }*/
        }

    }
}