/* PRODUTOS TEMPLATE 01 */

#homeproducts_01{
    padding-top: 3rem;
    padding-bottom: 3rem;
}

#homeproducts_01 .card{
    /*transition: .5s;
    border: unset;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);
    cursor: default;
    border-radius: 0;*/

    border: 1px solid rgba(0,0,0,.05);
    box-shadow: 0 1px 2px rgba(0,0,0,0.06), 0 1px 2px rgba(0,0,0,0.08);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    overflow: hidden;
    padding: 1rem;
    background-color: var(--gray-light);
}
#homeproducts_01 .card:hover {
    /*box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);*/
    box-shadow: 0 3px 6px rgba(0,0,0,0.06), 0 3px 6px rgba(0,0,0,0.08);
}
#homeproducts_01 .product-image {
    overflow: hidden;
}
#homeproducts_01 .product-image img {
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}
#homeproducts_01 .product-image:hover img {
    -ms-transform: scale(1.025);
    -webkit-transform: scale(1.025);
    -moz-transform: scale(1.025);
    -o-transform: scale(1.025);
    transform: scale(1.025);
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}
/*#homeproducts_01 .card-body {
    background-color: var(--gray-light);
}*/
#homeproducts_01 .card-title {
    color: var(--primary);
    font-size: 1.1rem;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1.35;
}
#homeproducts_01 .card-text p {
    font-size: 0.875rem;
    margin-bottom: 0;
}

#homeproducts_01 .card-text {
    /*display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 10;
    line-clamp: 10;
    -webkit-box-orient: vertical;
    overflow: hidden;*/
    line-height: 1.5;
}

.theme-03 #homeproducts_01 .btn-product, .theme-04 #homeproducts_01 .btn-product {
    background-color: var(--secondary);
    border: 1px solid var(--secondary);
    color: var(--white);
}
#homeproducts_01 .btn-product {
    font-size: 1rem;
	font-weight: 600;
    background-color: var(--primary);
    border: 1px solid var(--primary);
    color: var(--white);
    border-radius: 30px;
    padding: 0.5rem 1.125rem;
    -webkit-box-shadow: 0 1px 2px rgba(0,0,0,0.06), 0 1px 2px rgba(0,0,0,0.08);
    box-shadow: 0 1px 2px rgba(0,0,0,0.06), 0 1px 2px rgba(0,0,0,0.08);
}
.theme-03 #homeproducts_01 .btn-product:hover, .theme-03 #homeproducts_01 .btn-product:active,
.theme-03 #homeproducts_01 .btn-product.active, .open > .dropdown-toggle#homeproducts_01 .btn-product,
.theme-03 #homeproducts_01 .btn-product:focus, .theme-03 #homeproducts_01 .btn-product.focus,
.theme-04 #homeproducts_01 .btn-product:hover, .theme-04 #homeproducts_01 .btn-product:active,
.theme-04 #homeproducts_01 .btn-product.active, .open > .dropdown-toggle#homeproducts_01 .btn-product,
.theme-04 #homeproducts_01 .btn-product:focus, .theme-04 #homeproducts_01 .btn-product.focus{
    background-color: var(--primary) !important;
    border-color: var(--primary) !important;
}
#homeproducts_01 .btn-product:hover, #homeproducts_01 .btn-product:active,
#homeproducts_01 .btn-product.active, .open > .dropdown-toggle#homeproducts_01 .btn-product,
#homeproducts_01 .btn-product:focus, #homeproducts_01 .btn-product.focus{
    background-color: var(--secondary) !important;
    border-color: var(--secondary) !important;
}
.theme-03 #homeproducts_01 .btn-product.disabled, .theme-03 #homeproducts_01 .btn-product:disabled,
.theme-04 #homeproducts_01 .btn-product.disabled, .theme-04 #homeproducts_01 .btn-product:disabled{
    background-color: var(--secondary);
    border-color: var(--secondary);
}
#homeproducts_01 .btn-product.disabled, #homeproducts_01 .btn-product:disabled{
    background-color: var(--primary);
    border-color: var(--primary);
}



/* PRODUTOS */