
/*.page-01 .navbar-toggler {
    background-color: transparent
}

.page-01 .animated-icon3 span {
    background: var(--primary);
}*/

.page-01 .bg-navbar {
    background-color: var(--primary);
}

@media (max-width: 992px) { 
    .page-01 .bg-navbar {
        background-color: var(--white);
    }
}

@media (min-width: 992px) {
    .page-01 #navbar .navbar-expand-lg .navbar-nav > .nav-item > a:hover {
        text-decoration: underline;
    }
}
