/* NOTÍCIAS TEMPLATE 03 */

#homenews_03 {
    background-color: var(--black);
    padding-top: 3rem;
    padding-bottom: 3rem;
}

#homenews_03 .article-container{
    position: relative;
    padding: 1rem;
    border-radius: 0.375rem;
    background-color: rgba(240, 238, 255, 0.1);

    .article-image {
        overflow: hidden;
        position: relative;
        img {
            transform: scale(1);
            transition: all .3s ease-in-out;
        }
    }

    .article-body {
        padding-top: 15px;

        .timestamp {
            font-size: 0.75rem;
            font-style: italic;
            color: var(--primary);
            margin-top: 0.5rem;
        }
        
        .article-title {
            color: var(--white);
            font-size: 1.2rem;
            font-weight: 700;
            margin-bottom: 0.75rem;
            line-height: 1.35;
            transition: 0.3s;
        }
        .article-text {
            display: -webkit-box;
            text-overflow: ellipsis;
            -webkit-line-clamp: 3;
            line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            font-size: 0.875rem;
            color: var(--white);
        }
        .article-text p{
            font-size: 0.875rem;
            margin-bottom: 0;
        }
        .article-link {
            text-decoration: none;
            font-weight: bold;
            color: var(--primary);
        }
    }

    &:hover {

        .article-image img {
            transform: scale(1.1);
        }
        .article-body {
            .article-title {
                color: var(--primary);
            }
        }

    }
}