 /* TÍTULOS TEMPLATE 02 */
    .page-02 .title-properties {
        text-align: left;
    }

    .page-02 .titulo-pp {
        font-size: 1.2rem;
        padding: 0;
        line-height: 1px;
        /*margin: 0;*/
        letter-spacing: 1.5px;
        color: color-mix(in srgb, var(--primary), transparent 50%);
        position: relative;
    }

    .page-02 .titulo-pp:after {
        content: "";
        width: 8%;
        height: 1px;
        display: inline-block;
        background: var(--primary);
        margin: 4px 10px;
    }

    .page-02 .subtitulo-pp {
        font-size: 1.8rem;
        color: var(--secondary);
    }

    @media only screen and (max-width : 576px) {
        .page-02 .subtitulo-pp{
            font-size: 1.5rem;
        }
    }

/* END TÍTULOS TEMPLATE 02 */

#homebanner_and_form_02 {
    padding-top: 3rem;
    padding-bottom: 3rem;

    .form-banner{
        position: relative;
        height: 100%;

        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
    }

    .overlay-banner {
        background: rgba(0, 0, 0, 0.3);
        width: 100%;
        height: 100%;
         /*background: rgba(211, 215, 221, 0.6);
        background: rgb(140 111 41 / 75%);*/
    }
}

