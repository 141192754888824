/*.nav-effect{
    -webkit-transition: background 1.5s;
    -moz-transition: background 1.5s;
    -ms-transition: background 1.5s;
    -o-transition: background 1.5s;
    transition: background 1.5s;
    background: var(--white);
}*/

/*.sticky-top .nav-effect{
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 2px;
}*/

/*.fixed-top .scroll-navbar{
    background-image: url('https://temp.assec.pt/repo/alambique/goldenrock/static/assets/img/banner_topo.png');
    position: relative;
    height: auto;

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}*/

.topbar {
    background-color: #fafafa;
    padding: 0.5rem 0;
    /*top: 0;
    height: 31px;
    transform: translateY(0);
    background-color: #fafafa;
    -moz-transition: all 0.7s ease-in-out;
    -o-transition: all 0.7s ease-in-out;
    -ms-transition: all 0.7s ease-in-out;
    transition: all 0.7s ease-in-out;*/
}

/*.scroll-navbar .topbar {
    transform: translateY(-50px);
    height: 0;
    background-color: transparent;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    -moz-transition: all 0.7s ease-in-out;
    -o-transition: all 0.7s ease-in-out;
    -ms-transition: all 0.7s ease-in-out;
    transition: all 0.7s ease-in-out;
}*/

.topbar .topbar-aligment {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
}

.topbar .list-inline {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75rem;
    /*column-gap: 5px;*/
}
.topbar .list-inline button{
    padding: 0;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.topbar .list-inline button.btn_lang:hover,
.topbar .list-inline button.current_lang {
    color: var(--primary);
}

.topbar .list-inline .list-inline-item:not(:first-child):before {
    content: "|";
    margin-right: 0.5rem;
}

.svg-logo{
    width: 180px;
    padding: 0.5rem 0;
    -moz-transition: all 0.7s ease-in-out;
    -o-transition: all 0.7s ease-in-out;
    -ms-transition: all 0.7s ease-in-out;
    transition: all 0.7s ease-in-out;
    /*padding: 1rem 0;
    height: 100px;*/
}

/*.page-01 .svg-logo {
    width: 240px;
    padding: 0.5rem 0;
}*/

/*.scroll-navbar .svg-logo{
    height: 60px;
    -moz-transition: all 0.7s ease-in-out;
    -o-transition: all 0.7s ease-in-out;
    -ms-transition: all 0.7s ease-in-out;
    transition: all 0.7s ease-in-out;
}*/

.svg-logo-small{
    height: 40px;
}

#navbar .navbar-brand {
    z-index: 21;
}

#navbar .navbar-extra {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

#navbar .navbar-expand-lg .navbar-nav > .nav-item > a,
#navbar .navbar-extra > .nav-item a {
    color: var(--white);
    font-size: 0.875rem;
	font-weight: 500;
    text-transform: uppercase;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    /*cursor: pointer;
    padding-bottom: 0px;
    border-bottom: 2px solid transparent;*/
}

/*#navbar .navbar-expand-lg .navbar-nav > .nav-item > a:hover {
    color: var(--white);
    text-decoration: underline;
}*/

#navbar .navbar.navbar-expand-lg .navbar-nav .nav-item:last-child a {
    padding-right: 0 !important;

}

#navbar .scroll-navbar .navbar-expand-lg .navbar-nav .nav-item a,
#navbar .scroll-navbar.navbar-expand-lg .navbar-nav .nav-item a { 
    -moz-transition: all 0.7s ease-in-out;
    -o-transition: all 0.7s ease-in-out;
    -ms-transition: all 0.7s ease-in-out;
    transition: all 0.7s ease-in-out;
}

#navbar .navbar-extra > .nav-item a:hover{
    /*border-bottom: 2px solid var(--primary);*/
    color: var(--primary);
}

#navbar .navbar-expand-lg .navbar-nav > .nav-item > a.current_top{ 
    color: var(--white);
    text-decoration: underline;
}

#navbar .navbar-extra > .nav-item a.current_top{
    /*border-bottom: 2px solid var(--primary);*/
    color: var(--white);
}

#navbar .navbar-extra a.round-background {
    width: 33px;
    height: 33px;
    padding: 0;
    border-radius: 50%;
    color: var(--primary);
    border: 2px solid var(--primary);
    /*background: var(--white);*/
    display: flex;
    justify-content: center;
    align-items: center;
}

#navbar .navbar-extra a.dropdown-toggle {
    width: 33px;
    height: 33px;
    padding: 0;
    /*background: var(--white);*/
    display: flex;
    justify-content: center;
    align-items: center;
}

#navbar .navbar-extra a.round-background:hover,
#navbar .navbar-extra a.dropdown-toggle:hover {
    border-color: var(--primary);
    text-decoration: none;
}

#navbar .navbar-extra a.round-background::before,
#navbar .navbar-extra a.dropdown-toggle::before {
    position: absolute;
    top: calc(100% + 3px);
    font-family:'Bootstrap-icons';
    content:'\F229';
    font-size: 0.625rem;
    color: var(--primary);
    transition: all 0.7s ease-in-out;
}

#navbar .navbar-extra a.round-background:hover::before,
#navbar .navbar-extra a.dropdown-toggle:hover::before { 
    top: calc(100% + 6px);
    color: var(--primary);
}

/*#navbar .navbar-extra button:hover,
#navbar .navbar-extra button.current_lang {
    color: var(--primary);
    background-color: transparent;
}*/

.navbar-toggler{
    /*background-color:#FFF;
    padding: 0.75rem 0.75rem;*/
    z-index: 21;
    border: 0;
}

.navbar-toggler:focus{
    outline: 0;
}

/* Dropdown-menu */

    #navbar .dropdown-menu {
        min-width: max-content;
        border: none;
        /*propriedades gerais para aplicar efeito de transição no menu dropdown */
        opacity: 0;
        pointer-events: none;
        -webkit-box-shadow: 0px 2px 10px rgba(0, 0, 0, .175);
        box-shadow: 0px 2px 10px rgba(0, 0, 0, .175);
        -moz-transition: all 0.5s ease-in-out;
        -o-transition: all 0.5s ease-in-out;
        -ms-transition: all 0.5s ease-in-out;
        transition: all 0.5s ease-in-out;
         /* end */
    }

    /*propriedades para aplicar efeito de transição no menu dropdown */
    #navbar .dropdown-menu.show { 
        opacity: 1;
        pointer-events: auto;
    }

    #navbar .navbar-extra .dropdown-menu {
        /*propriedade para aplicar sempre efeito de transição no menu dropdown */
        display: block;
        /* end*/
        top: 55px;
        left: -15px;
    }

    #navbar .navbar-extra .dropdown-menu.dropdown-menu-right {
        left: unset;
        right: -15px;
    }


    #navbar .navbar-expand-lg .dropdown-menu > a.dropdown-item,
    #navbar .navbar-extra .dropdown-menu > .dropdown-item
    /*#navbar .navbar-extra .dropdown-menu > a.dropdown-item,
    #navbar .navbar-extra .dropdown-menu > button.dropdown-item,
    #navbar .navbar-extra .dropdown-menu > span.dropdown-item*/ {
        color: var(--body);
        font-weight: 700;
        font-size: 0.875rem;
        background-color: unset;
        text-transform: uppercase;
        transition: all 0.7s ease-in-out;
    }


    #navbar .navbar-expand-lg .dropdown-menu a:hover,
    #navbar .navbar-extra .dropdown-menu .dropdown-item:hover,
    #navbar .navbar-expand-lg .dropdown-menu a:focus,
    #navbar .navbar-extra .dropdown-menu .dropdown-item:focus {
        color: var(--primary);
    }

    #navbar .navbar-expand-lg .navbar-nav .nav-item .dropdown-menu a.current_top{ 
        color: var(--primary);
        /*text-decoration: underline;*/
    }

    #navbar .navbar-extra .dropdown-menu > .dropdown-item.current_lang {
        color: var(--primary);
    }

    .dropdown-toggle:after { content: none }

/* End Dropdown-menu */

.button_no_border{    
    border: 0px;
    background:transparent;
    cursor: pointer;
}

.button_no_border:focus{    
    border: 0px;
    outline: 0px;
}

.h-0-transition{
    height: 0;
    -moz-transition: all 2s ease-in-out;
    -o-transition: all 2s ease-in-out;
    -ms-transition: all 2s ease-in-out;
    transition: all 2s ease-in-out;
}

@media (max-width: 992px) {
    #navbar .navbar-expand-lg .navbar-nav > .nav-item > a.current_top {
        color: var(--primary);
        text-decoration: unset;
    }
}

@media (max-width: 767px) {
    .topbar-mobile {
        height: 70px !important;
        -moz-transition: all 0.7s ease-in-out;
        -o-transition: all 0.7s ease-in-out;
        -ms-transition: all 0.7s ease-in-out;
        transition: all 0.7s ease-in-out;
    }

    .scroll-navbar .topbar-mobile {
        transform: translateY(-100px) !important;
        height: 0 !important;
        -moz-transition: all 0.7s ease-in-out;
        -o-transition: all 0.7s ease-in-out;
        -ms-transition: all 0.7s ease-in-out;
        transition: all 0.7s ease-in-out;
    }

    /*.svg-logo{
        width: 85px;
        -moz-transition: all 0.7s ease-in-out;
        -o-transition: all 0.7s ease-in-out;
        -ms-transition: all 0.7s ease-in-out;
        transition: all 0.7s ease-in-out;
    }*/
    
    /*.scroll-navbar .svg-logo{
        height: 50px;
        -moz-transition: all 0.7s ease-in-out;
        -o-transition: all 0.7s ease-in-out;
        -ms-transition: all 0.7s ease-in-out;
        transition: all 0.7s ease-in-out;
    }*/

    #navbar .navbar-expand-lg .navbar-nav > .nav-item > a,
    #navbar .navbar-extra > .nav-item a { 
        font-size: 1.3rem;
    }
}

@media only screen and (min-width : 992px) {

    /*propriedade para aplicar efeito de transição no menu dropdown > 992px*/
    #navbar .dropdown-menu { 
        display: block;
    }

    /*open dropdown-menu on hover (>992px)
     #navbar .navbar-extra .dropdown:hover > .dropdown-menu*/
    /*#navbar .navbar-expand-lg .dropdown:hover > .dropdown-menu {
        display: block;
        propriedade para aplicar efeito de transição no menu dropdown
        opacity: 1;
    }*/

}

/* WITH FIXED SIDE NAVIGATION MENU */

    #sideNavigationMenu {
        display: flex;
        flex-direction: column;
        justify-content: center;
        /*background: rgba(37, 44, 48, 1);*/
        background: var(--secondary);
        position: fixed;
        z-index: 20;
        top: 0px;
        right: 0;
        height: 100%;
        width: 100%;
        padding: 120px 40px 60px 40px;
        overflow-y: auto;
        -webkit-transform: translateX(100%);
        -moz-transform: translateX(100%);
        -ms-transform: translateX(100%);
        -o-transform: translateX(100%);
        transform: translateX(100%);
        -webkit-transition: transform 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86);
        -moz-transition: transform 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86);
        -o-transition: transform 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86);
        transition: transform 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    }
    /* quando o menu é sempre fixed em todas as resoluções */
    /*#sideNavigationMenu.show {
        -webkit-transform: translateX(0px);
        -moz-transform: translateX(0px);
        -ms-transform: translateX(0px);
        -o-transform: translateX(0px);
        transform: translateX(0px);
    }*/

    /*#sideNavigationMenu ul.navbar-nav {
        list-style-type: none;
    }*/
    #sideNavigationMenu ul.navbar-nav li {
        -webkit-transform: translateX(40px);
        -moz-transform: translateX(40px);
        -ms-transform: translateX(40px);
        -o-transform: translateX(40px);
        transform: translateX(40px);
        opacity: 0;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
        /*float: none;*/
        color: #fff;
    }

    #sideNavigationMenu.show ul.navbar-nav li {
        -webkit-transform: translateX(0px);
        -moz-transform: translateX(0px);
        -ms-transform: translateX(0px);
        -o-transform: translateX(0px);
        transform: translateX(0px);
        opacity: 1;
    }

    #sideNavigationMenu ul.navbar-nav > li.nav-item > a{
        color: var(--white);
        font-size: 1.3rem;
        font-weight: 500;
        text-align: center;
    }

    #sideNavigationMenu .dropdown-menu {
        border: none;
        background-color: transparent;

         /*propriedades para aplicar efeito de transição nos dropdowns do menu fixed (antes de estar aberto) */
         display: block;
         padding: 0;
         margin: 0;
         max-height: 0;
         /*end*/

        /*-webkit-box-shadow: 0px 2px 10px rgba(0, 0, 0, .175);
		box-shadow: 0px 2px 10px rgba(0, 0, 0, .175);*/
    }

    /* propriedades para aplicar efeito de transição nos dropdowns do menu fixed ao abrir */
    /* repõe os valores da margem, padding e um valor máximo de altura*/
    #sideNavigationMenu .dropdown-menu.show {
        padding: .5rem 0;
        margin: .125rem 0 0;
        max-height: 300px;
    }

    #sideNavigationMenu .nav-item.dropdown .dropdown-menu > a.dropdown-item {
        color: var(--white);
        /*font-size: 20px;*/
        font-weight: 700;
        text-align: center;
        background-color: unset;

        /*-webkit-transform: translateX(40px);
        -moz-transform: translateX(40px);
        -ms-transform: translateX(40px);
        -o-transform: translateX(40px);
        transform: translateX(40px);
        opacity: 0;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;*/
    }

    /*#sideNavigationMenu .nav-item.dropdown .dropdown-menu.show > a.dropdown-item {

        -webkit-transform: translateX(0px);
        -moz-transform: translateX(0px);
        -ms-transform: translateX(0px);
        -o-transform: translateX(0px);
        transform: translateX(0px);
        opacity: 1;
    }*/

    #sideNavigationMenu .nav-item.dropdown .dropdown-menu > a.dropdown-item:hover,
    #sideNavigationMenu .nav-item.dropdown .dropdown-menu > a.dropdown-item:focus,
    #sideNavigationMenu .nav-item.dropdown .dropdown-menu > a.dropdown-item.current_top {
        color: var(--primary);
    }


    /*#sideNavigationMenu .dropdown-menu {
		background-color: var(--white);
		border: none;
		border-radius: 5px;
		padding: 10px 0;
		min-width: max-content;
		border-top: 1px solid #E1E1E1;
		-webkit-box-shadow: 0px 2px 10px rgba(0, 0, 0, .175);
		box-shadow: 0px 2px 10px rgba(0, 0, 0, .175);
	}*/

	/*#sideNavigationMenu .dropdown-menu a {
		padding: 0 10px;
		clear: both;
		line-height: 2;
		white-space: nowrap;
		outline: none !important;
		color: var(--black);
		font-size: 0.875rem;
		font-weight: 700;
		line-height: 24px;
	}*/

	/*#sideNavigationMenu .dropdown-menu a:hover,
	#sideNavigationMenu .dropdown-menu a:focus {
		color: var(--primary);
	}*/

    #sideNavigationMenu ul.navbar-nav .nav-item.dropdown.show .nav-link { 
        color: var(--primary);
    }

    #sideNavigationMenu ul.navbar-nav .nav-item a:hover,
    #sideNavigationMenu ul.navbar-nav .nav-item a.current_top { 
        color: var(--primary);
    }

    #sideNavigationMenu.show ul.navbar-nav li:nth-child(1) {
        transition-delay: 0.6s;
    }
    #sideNavigationMenu.show ul.navbar-nav li:nth-child(2) {
        transition-delay: 0.8s;
    }
    #sideNavigationMenu.show ul.navbar-nav li:nth-child(3) {
        transition-delay: 1s;
    }
    #sideNavigationMenu.show ul.navbar-nav li:nth-child(4) {
        transition-delay: 1.2s;
    }
    #sideNavigationMenu.show ul.navbar-nav li:nth-child(5) {
        transition-delay: 1.4s;
    }
    #sideNavigationMenu.show ul.navbar-nav li:nth-child(6) {
        transition-delay: 1.6s;
    }
    #sideNavigationMenu.show ul.navbar-nav li:nth-child(7) {
        transition-delay: 1.8s;
    }
    #sideNavigationMenu.show ul.navbar-nav li:nth-child(8) {
        transition-delay: 2s;
    }
    #sideNavigationMenu.show ul.navbar-nav li:nth-child(9) {
        transition-delay: 2.2s;
    }
    #sideNavigationMenu.show ul.navbar-nav li:nth-child(10) {
        transition-delay: 2.4s;
    }
    #sideNavigationMenu.show ul.navbar-nav li:nth-child(11) {
        transition-delay: 2.6s;
    }

    /*#sideNavigationMenu.show ul.navbar-nav.social-media li:nth-child(1) {
        transition-delay: 2s;
    }*/

    /*#sideNavigationMenu.show  .nav-item.dropdown .dropdown-menu.show .dropdown-item:nth-child(1){ 
        transition-delay: 1.6s;
    }
    #sideNavigationMenu.show  .nav-item.dropdown .dropdown-menu.show .dropdown-item:nth-child(2){ 
        transition-delay: 1.8s;
    }*/

    #sideNavigationMenu.show ul.navbar-nav.social-media li a {
        font-size: 1.5rem;
    }

    #sideNavigationMenu.show ul.navbar-nav.social-media li img:hover,
    #sideNavigationMenu.show ul.navbar-nav.social-media li i:hover{
        filter: drop-shadow(0px 0px 20px var(--primary));
    }

    #sideNavigationMenu.show .about,
    #sideNavigationMenu.show .social,
    #sideNavigationMenu.show ul.sub {
        -webkit-transform: translateY(0px);
        -moz-transform: translateY(0px);
        -ms-transform: translateY(0px);
        -o-transform: translateY(0px);
        transform: translateY(0px);
        opacity: 1;
        transition-delay: .85s;
    }

    .animated-icon3 {
        width: 30px;
        height: 22px;
        position: relative;
        margin: 5px;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .5s ease-in-out;
        -moz-transition: .5s ease-in-out;
        -o-transition: .5s ease-in-out;
        transition: .5s ease-in-out;
        cursor: pointer;
    }

    .animated-icon3 span {
        display: block;
        background: var(--primary);
        position: absolute;
        height: 3px;
        width: 100%;
        border-radius: 9px;
        opacity: 1;
        left: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .5s ease-in-out;
        -moz-transition: .5s ease-in-out;
        -o-transition: .5s ease-in-out;
        transition: .5s ease-in-out;
    }

    button[aria-expanded="true"] .animated-icon3 span {
        background: var(--white);
    }

    .animated-icon3 span:nth-child(1) {
        top: 0px;
        -webkit-transform-origin: left center;
        -moz-transform-origin: left center;
        -o-transform-origin: left center;
        transform-origin: left center;
    }

    .animated-icon3 span:nth-child(2) {
        width: 80%;
        top: 10px;
        -webkit-transform-origin: left center;
        -moz-transform-origin: left center;
        -o-transform-origin: left center;
        transform-origin: left center;
    }

    .animated-icon3 span:nth-child(3) {
        top: 20px;
        -webkit-transform-origin: left center;
        -moz-transform-origin: left center;
        -o-transform-origin: left center;
        transform-origin: left center;
    }

    button[aria-expanded="true"] .animated-icon3 span:nth-child(1) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
        top: 0px;
        left: 8px;
    }

    button[aria-expanded="true"] .animated-icon3 span:nth-child(2) {
        width: 0%;
        opacity: 0;
    }

    button[aria-expanded="true"] .animated-icon3 span:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
        top: 21px;
        left: 8px;
    }

    @media (max-width: 992px) {
        /*.navbar-collapse{
            background-color: var(--white);
        }*/

        /*.navbar-expand-lg.navbar-collapse{
            display: none !important;

        }*/
        #sideNavigationMenu.show {
            -webkit-transform: translateX(0px);
            -moz-transform: translateX(0px);
            -ms-transform: translateX(0px);
            -o-transform: translateX(0px);
            transform: translateX(0px);
        }
        #sideNavigationMenu.show ul.navbar-nav.social-media li:nth-child(1) {
            transition-delay: 2.2s;
        }
    }

    @media (max-width: 767px) { 
        #sideNavigationMenu { 
            display: block;
            /*padding: 150px 40px 60px 40px;*/
        }
        /*#sideNavigationMenu ul.navbar-nav > li.nav-item > a {
            font-size: 22px;
        }*/
    }

/* WITH FIXED SIDE NAVIGATION MENU */