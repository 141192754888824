
/* NOTÍCIAS WELCOME TEMPLATE 01*/

#homenews_01 {
    padding-top: 3rem;
    padding-bottom: 3rem;
}

#homenews_01 .card:hover {
    transform: translateY(20px);
    /*background-color: var(--secondary);*/
}

#homenews_01 .timestamp {
    font-size: 80%;
    margin-bottom: 0.5rem;
}

#homenews_01 .card-title {
    color: var(--primary);
    font-size: 1.1rem;
    font-weight: 700;
    margin-bottom: 0.75rem;
    line-height: 1.35;
}
#homenews_01 .card-text p {
    margin-bottom: 0;
}
#homenews_01 .card-text {
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 4;
    line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 0.875rem;
}

#homenews_01 .btn-text {
    font-weight: 500;
    letter-spacing: 0.75px;
    color: var(--body);
    background-color: var(--gray-light);
    border-radius: 30px;
    text-decoration: none;
    transition: all 0.3s ease 0s;
}

#homenews_01 .btn-text:hover {
    color: var(--white);
    background-color: var(--secondary);
}

#homenews_01 .bi.bi-chevron-left,
#homenews_01 .bi.bi-chevron-right {
    font-size: medium;
    margin-left: 5px;
    color: var(--primary);
    transition: all .4s ease-out;
}

/* END NOTÍCIAS */
