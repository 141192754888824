
/* CALL TO ACTION LANDING PAGE TEMPLATE 02*/

#homecalltoaction_02{
    padding-top: 5rem;
    padding-bottom: 5rem;
    /*background-color: var(--primary);*/

    .cta-content{
        padding: 3rem;
        color: var(--white);
        background-color: var(--primary); 
        border-radius: 0.375rem;

        .cta-title {
            /*color: var(--secondary);*/
            font-size: 2.2rem;
            font-weight: 700;
            margin-bottom: 1rem;
        }

        .cta-description {
            font-size: 1rem;
            white-space: pre-wrap;
        }

        /*a {
            margin-top: 1rem;
        }*/

        @media (max-width: 767px) { 
            padding: 3rem 0;
        }
    }

    @media (max-width: 767px) { 
        background-color: var(--primary);
    }
}

/* END CALL TO ACTION */
