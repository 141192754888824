::placeholder {
    color: #969696 !important;
    font-size: small;
}

#assecform .form-select{
    font-size: .875rem;
}

#assecform a{
    color: var(--bs-gray-300);
    text-decoration: underline;
}
#assecform a:hover, #assecform a:focus {
    color: var(--bs-gray-100);
}

#assecform .form-check {
    font-size: 0.875rem;
}

.page-01 #assecform,
.page-04 #assecform {
    position: absolute;
    top: 0;
    z-index: 9;
}

.page-01 #assecform {
    right: 75px;
}

.page-04 #assecform {
    left: 75px;
}
#assecform .div-form {
    color: var(--white);
    background-color: var(--black);
    padding: 3rem;
}
.page-01 #assecform .div-form,
.page-04 #assecform .div-form {
    width: 75%;
}
/*.page-03 #assecform .div-form {
    background-color: var(--black);
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    
}*/

#assecform .div-form .form-title {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: .5rem;
}

#assecform .div-form .form-subtitle { 
    margin-bottom: 1.5rem;
}

#assecform .div-form #canv {
    float: left;
    width: 175px;
    height: 30px;
    background-color: white;
}
#assecform .div-form #reload_href {
    float: left;
    font-size: 20px;
}
#assecform .div-form #reload_href i {
    margin-left: 10px;
}
#assecform .div-form #user_captcha_input {
    height: 30px;
    padding-top: 5px;
}

@media (max-width: 1023px) {
    .page-01 #assecform,
    .page-04 #assecform {
        position: relative;
        top: unset;
        right: unset;
        left: unset;
    }
    .page-01 #assecform .div-form,
    .page-04 #assecform .div-form {
        width: 100%;
    }
}

@media (max-width: 767px) { 
    #assecform .div-form { 
        padding: 1.5rem 2rem;
    }
}

@media (min-width: 992px) {
    .page-01 #assecform .div-form {
        float: right;
        margin-top: 3rem;
        max-width: 760px;
        min-width: 530px;
    }
    .page-04 #assecform .div-form {
        float: left;
        margin-top: 3rem;
        max-width: 760px;
        min-width: 530px;
    }
}