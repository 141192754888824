
/* LINKS/PDF'S LANDING PAGE TEMPLATE 01*/

#homelinks_01{
    padding-top: 3rem;
    padding-bottom: 3rem;

    .btn-download {
        width: 100%;
        padding: 12px;
        border: 1px solid var(--bs-gray-400);
        border-radius: 0;
        font-weight: 600;
        text-wrap: balance;

        -webkit-transition: all 0.3s ease-out;
        -moz-transition: all 0.3s ease-out;
        -o-transition: all 0.3s ease-out;
        transition: all 0.3s ease-out;
    }
    .btn-download:hover, .btn-download:active,
    .btn-download.active, .open > .dropdown-toggle.btn-download,
    .btn-download:focus, .btn-download.focus{
        color: var(--primary);
        border-bottom: 1px solid var(--primary);
        /*background-color: var(--primary);
        text-decoration: underline;*/
    }

    .flex-align-items {
        display: inline-flex;
        flex-wrap: wrap;
        gap: 15px 0px;
    }
}

/* END LINKS/PDF'S */
