/* MARCAS TEMPLATE 02 */

#homebrands_02 {
    padding-top: 3rem;
    padding-bottom: 3rem;
}

#homebrands_02 .brand-container img{
    padding: 1rem;
    transition: all .2s ease-in-out;
    
    &:hover{
        box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
        transform: translate3d(0, -2px, 0);
        /*box-shadow: 0 .25rem 1rem rgba(0,0,0,.1)*/
    }
}