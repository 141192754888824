.normal .content-body img,
.list .content-body img,
.list-alt .content-body img,
.tabs .content-body img,
.accordion .content-body img {
    max-height: 50vh;
    width: 100%;
    object-fit: cover;
    object-position: 85% 50%;
}

/*.accordion .content-body .div-image {
    max-height: 400px;
    overflow: hidden;
}*/

.content-body .div-content .title {
    color: var(--cinza-escuro);
    font-weight: 400;
    font-size: 1.20rem;
    text-transform: uppercase;
    position: relative;
    padding-left: 60px;
}

.content-body .div-content .title:after {
    position: absolute;
    top: 50%;
    left: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    height: 5px;
    width: 3rem;
    background-color: var(--laranja);
    content: "";
}

.content-body .iso-nav button {
    border-radius: 0;
}

.content-body .iso-nav button.active,
.content-body .iso-nav button:hover {
    background-color: var(--cinza-escuro) !important;
    border-color: var(--cinza-escuro) !important;
    outline: none !important;
    color: #FFF !important;
}

.content-body .div-scroll div {
    position: absolute;
    bottom: 20px;
    left: 50%;
    padding-top: 60px;
    z-index: 2;
    display: inline-block;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    color: var(--cinza);
    text-decoration: none;
    transition: opacity .3s;
}

.content-body .div-scroll div:hover {
    opacity: 0.5;
}

.content-body .div-scroll div span {
    position: absolute;
    top: 0;
    left: 50%;
    width: 30px;
    height: 50px;
    margin-left: -15px;
    border: 2px solid var(--cinza);
    border-radius: 50px;
    box-sizing: border-box;
}

.content-body .div-scroll div span:before {
    position: absolute;
    top: 10px;
    left: 50%;
    content: '';
    width: 6px;
    height: 6px;
    margin-left: -3px;
    background-color: var(--cinza);
    border-radius: 100%;
    -webkit-animation: scroll-dott 2s infinite;
    animation: scroll-dott 2s infinite;
    box-sizing: border-box;
}

.content-body .div-items .item {
    width: 23%;
    padding: 1%;
    float: left;
}

.content-body .div-items .loading {
    clear: both;
}

@-webkit-keyframes scroll-dott {
    0% {
        -webkit-transform: translate(0, 0);
        opacity: 0;
    }
    40% {
        opacity: 1;
    }
    80% {
        -webkit-transform: translate(0, 20px);
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}
@keyframes scroll-dott {
    0% {
        transform: translate(0, 0);
        opacity: 0;
    }
    40% {
        opacity: 1;
    }
    80% {
        transform: translate(0, 20px);
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}

/* ACCORDION PAGE */

.element{
    float: right;
    justify-content: center;
    shape-margin: 7em;
}

main.accordion .card-header {
    padding: 1.25rem 1.25rem;
    /*margin-bottom: 0;*/
    background-color: var(--gray-light);
    border-bottom: 0px;
}

main.accordion .card-header button {
    font-size: 1rem;
    color: var(--body);
    text-align: left;
    column-gap: 10px;
}

main.accordion .card-header button .bi-chevron-down {
    color: var(--primary);
    font-size: 1.5rem;
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -ms-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
}

main.accordion .card-header button[aria-expanded="true"] .bi-chevron-down {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
}


/* END ACCORDION PAGE */

/* TABS/PILLS PAGE */

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: var(--white);
    background-color: var(--primary);
}

/* END TABS/PILLS PAGE */


/* PRESS/CLIPPING PAGE */

.press-date {
    font-size: 0.875rem;
    margin: 0.5rem 0;
}

.press-title {
    font-weight: bold;
    margin-bottom: 1rem;
}

.press-abstract {
    /*display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: hidden;*/
    margin-bottom: 1rem;
}

.press-text {
    margin-bottom: 1rem;
}

/* END PRESS/CLIPPING PAGE */

/*@media (max-width: 576px){
    .normal .content-body .div-image {
        height: auto;
    }
}*/

.accommodation-cards .card-bar::before,
.routes-cards .card-bar::before,
.restaurant-cards .card-bar::before,
.event-cards .card-bar::before,
.novidades-cards .card-bar::before,
.animation-cards .card-bar::before {
    content: "";
    display: block;
    width: 50px;
    height: 10px;
    border-radius: 5px;
    background: var(--orange);
    position: absolute;
}
.accommodation-cards .card-bar::before {
    background: rgb(94, 52, 38);
}
.routes-cards .card-bar::before {
    background: rgb(64, 141, 212);
}
.restaurant-cards .card-bar::before {
    background: rgb(200, 99, 32);
}
.event-cards .card-bar::before {
    background: rgb(111, 84, 170);
}
.novidade-cards .card-bar::before {
    background: rgb(158, 200, 58);
}
.animation-cards .card-bar::before {
    background: rgb(234, 180, 46);
}


.accommodation-cards a:hover,
.routes-cards a:hover,
.restaurant-cards a:hover,
.event-cards a:hover,
.novidade-cards a:hover,
.animation-cards a:hover {
    text-decoration: none;
}

.accommodation-cards .card-title,
.routes-cards .card-title,
.restaurant-cards .card-title,
.event-cards .card-title,
.novidade-cards .card-title,
.animation-cards .card-title {
    color: var(--body);
    font-size: 1.728rem;
    line-height: normal;
}

.accommodation-cards .card-text,
.routes-cards .card-text,
.restaurant-cards .card-text,
.event-cards .card-text,
.novidade-cards .card-text,
.animation-cards .card-text {
    color: var(--body);
    font-size: 16px;
}
