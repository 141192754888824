/* GALERIA WELCOME TEMPLATE 02 */

#homegallery_02{
    padding-top: 3rem;
    padding-bottom: 3rem;
}

#homegallery_02 .gallery-item {
    border-radius: 0;
    overflow: hidden;
    border-right: 3px solid var(--white);
    border-bottom: 3px solid var(--white);
}
#homegallery_02 .gallery-item img {
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}
#homegallery_02 .gallery-item:hover img {
    -ms-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}