@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');
/* font-family: 'Poppins', sans-serif;*/

:root {
    --primary: rgb(194, 151, 66);
    --secondary: rgb(66, 58, 42);
    --white: rgb(255,255,255);
    --blue: rgb(0, 105, 209);
    --blue-light: #83a1b2;
    --blue-dark: #102534;
    --orange: rgb(200, 99, 32);
    --red: #e4294e;
    --green: #6ca66c;
    --yellow: #8C6F29;
    --gray-dark: #141414;
    --gray: rgb(232, 241, 246);
    --gray-light: rgb(247, 247, 247);
    --body: rgb(0 0 0 / 80%);
    --black: rgb(15, 15, 15);

    --alert: rgb(163 19 28);
}

html {
    scroll-behavior: smooth;
}

/*
.page-01.theme-01,
.page-02.theme-01,
.page-03.theme-01 {
    --primary: rgb(194, 151, 66);
    --secondary: rgb(66, 58, 42);
}
.page-01.theme-02,
.page-02.theme-02,
.page-03.theme-02 {
    --primary: rgb(255, 131, 67);
    --secondary: rgb(125, 34, 17);
}
.page-01.theme-03,
.page-02.theme-03,
.page-03.theme-03 {
    --primary: rgb(162, 202, 113);
    --secondary: rgb(56, 127, 57);
}
.page-01.theme-04,
.page-02.theme-04,
.page-03.theme-04 {
    --primary: rgb(110, 172, 218);
    --secondary: rgb(19, 75, 112);
}
.page-01.theme-05,
.page-02.theme-05,
.page-03.theme-05 {
    --primary: rgb(80, 80, 80);
    --secondary: rgb(33, 33, 33);
}*/

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.5px;
  color: var(--body);
  background-color: var(--white);
  text-wrap: pretty;
}

*:focus {
    outline: none !important;
    box-shadow: 0 0 0 0 transparent !important;
   }

::selection {
    background: rgb(166 150 51 / 25%);
    color: #FFF;
}

p {
    /*margin: 0px 0px 10px 0px;*/
    line-height: 1.75;
}
  
a {
	color: var(--primary);
    word-break: break-word;
	transition: all .2s;
    text-decoration: none;
}
a:hover {
    color: var(--secondary);
    outline: none;
}

a.color{
    color: var(--blue);
}
a.color-red{
    color: var(--red);
}
a.color-orange{
    color: var(--orange);
}

/* MARGINS && PADDINGS */
    .mt-100 {
        margin-top: 100px;
    }
    .py-100 {
        padding-top: 100px;
        padding-bottom: 100px;
    }
    #info.py-100 {
        padding-top: 85px;
        padding-bottom: 85px;
    }
/* end MARGINS && PADDINGS */

/* JUSTIFY-CONTENT */

    .justify-content-evenly {
        justify-content: space-evenly;
    }

/* END JUSTIFY-CONTENT */

/* BORDER-RADIUS */
    .rounded-10 {
        border-radius: 10px;
    }

    .rounded-20 {
        border-radius: 20px;
    }

/* END BORDER-RADIUS */

.btn{
    white-space: normal;
    padding: .375rem 1.125rem;
    font-size: 0.875rem;
}
.btn-primary {
    font-size: 0.875rem;
	font-weight: 700;
    background-color: var(--primary);
    border: 1px solid var(--primary);
    color: var(--white);
    border-radius: 30px;
}
.btn-primary:hover, .btn-primary:active,
.btn-primary.active, .open > .dropdown-toggle.btn-primary,
.btn-primary:focus, .btn-primary.focus{
    background-color: var(--secondary) !important;
    border-color: var(--secondary) !important;
    outline: none;
    color: var(--white);
    box-shadow: none;
}
.btn-primary.disabled, .btn-primary:disabled{
    background-color: var(--secondary);
    border-color: var(--secondary);
}
.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0rem rgba(16, 37, 52,.5);
}
.btn-secondary {
    background-color: var(--primary);
    border: 1px solid var(--primary);
    color: var(--white);
    border-radius: 0px;
}
.btn-secondary:hover, .btn-secondary:active,
.btn-secondary.active, .open > .dropdown-toggle.btn-secondary,
.btn-secondary:focus, .btn-secondary.focus{
    background-color: var(--white) !important;
    border-color: var(--primary) !important;
    outline: none;
    color: var(--primary) !important;
    box-shadow: none;
}
.btn-secondary.disabled, .btn-secondary:disabled{
    background-color: var(--white);
    border-color: var(--primary);
}
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show>.btn-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0rem rgba(16, 37, 52,.5);
}

.btn-alert {
    padding: 0.5rem 1.125rem;
    font-size: 1.25rem;
    font-weight: 600;
    background-color: var(--alert);
    border: 1px solid var(--alert);
    color: var(--white);
    border-radius: 25px;
    /*border-radius: calc(infinity * 1px);*/
}
.btn-alert:hover, .btn-alert:active,
.btn-alert.active, .open > .dropdown-toggle.btn-alert,
.btn-alert:focus, .btn-alert.focus{
    background-color: color-mix(in srgb, var(--alert), white 10%);
    border-color: var(--alert);
    color: var(--white);
}

/* expand color on hover button */
.btn-expand {
    position: relative;
    transition: all 0.85s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.btn-expand > *{
    position: relative;
}
.btn-expand:before {
    transition: all 0.85s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    content: "";
    height: 100%;
    width: 25%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50px;
    /*border-radius: calc(infinity * 1px);*/
    background: rgba(var(--primary-rgb), 0.5);
}
.btn-expand:hover:before {
    width: 100%;
    background: rgba(var(--primary-rgb), 1);
}
.btn-expand i {
    font-size: 75%;
    /*-webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;*/
}
.btn-expand i.bi-arrow-right {
    margin-left: 5px;
}
.btn-expand i.bi-arrow-left {
    margin-right: 5px;
}
.btn-expand:hover{
    color: var(--white);
}
/*.btn-expand:hover i.bi-arrow-right{
    margin-left: 10px;
}
.btn-expand:hover i.bi-arrow-left{
    margin-right: 10px;
}*/
/* end expand color on hover button */


.btn-link{
    color: var(--secondary);
    -webkit-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.btn-link i{
    font-size: 75%;
    -webkit-transition: all 0.4s ease-out;
    -moz-transition: all 0.4s ease-out;
    -o-transition: all 0.4s ease-out;
    transition: all 0.4s ease-out;
}

.btn-link i.bi-arrow-right {
    margin-left: 5px;
}
.btn-link i.bi-arrow-left {
    margin-right: 5px;
}

.btn-link:hover{
    color: var(--primary);
    text-decoration: underline;
}

.btn-link:hover i.bi-arrow-right{
    margin-left: 10px;
}
.btn-link:hover i.bi-arrow-left{
    margin-right: 10px;
}

button.btn-link:hover,
button.btn-link:focus{
    text-decoration: none;
}

.txt-btn {
    color: var(--secondary);
    -webkit-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}
.txt-btn:hover {
    color: var(--primary) !important;
    text-decoration: underline;
}

.txt-btn > .fa-long-arrow-alt-right,
.txt-btn > .fa-long-arrow-alt-left {
    margin-left: 5px;
    margin-right: 5px;
    font-size: 75%;
    -webkit-transition: all 0.4s ease-out;
    -moz-transition: all 0.4s ease-out;
    -o-transition: all 0.4s ease-out;
    transition: all 0.4s ease-out;
}
.txt-btn:hover > .fa-long-arrow-alt-right {
    margin-left: 10px;
}
.txt-btn:hover > .fa-long-arrow-alt-left«t {
    margin-right: 10px;
}

.accordion .btn{
    background-color: transparent;
}

/*.accordion button.collapsed:before{
    font-family: "Font Awesome 5 Free";
    font-size: 0.7rem;
    font-weight: 900; 
    content: "\f067";
    float: left;
    margin-right: 15px;
    color: white;
    border-radius: 50%;
    padding: 2.5px 6px;
}
.accordion button:before{
    font-family: "Font Awesome 5 Free";
    font-size: 0.7rem;
    font-weight: 900; 
    content: "\f068";
    float: left;
    margin-right: 15px;
    color: white;
    border-radius: 50%;
    padding: 2.5px 6px;
}
.accordion button.btn-link.collapsed:before{
    background-color: var(--blue);
}
.accordion button.btn-link:before{
    background-color: var(--blue);
}*/
.accordion button.btn-link-red.collapsed:before{
    background-color: var(--red);
}
.accordion button.btn-link-red:before{
    background-color: var(--red);
}
.accordion button.btn-link-orange.collapsed:before{
    background-color: var(--orange);
}
.accordion button.btn-link-orange:before{
    background-color: var(--orange);
}

.title-properties {
    text-align: center;
    margin-bottom: 3rem;
}

.title-properties hr {
    display: none;
    margin: 10px auto;
    border-top: 3px solid var(--primary);
    width: 8%;
    opacity: 1;
}

.titulo-pp{
    color: var(--secondary);
    font-size: 2rem;
    font-weight: 700;
    text-transform: uppercase;
}

.subtitulo-pp{
    color: var(--primary);
    font-weight: 700;
    font-size: 1.125rem;
    text-transform: uppercase;
}
@media only screen and (max-width : 576px) {
    .titulo-pp{
        font-size: 1.5rem;
        font-weight: 700;
    }
}

.titulo-primario, .titulo-secundario {
    color: var(--secondary);
    font-size: 2rem;
    font-weight: 700;
    line-height: 1;
}
.titulo-primario span, .titulo-secundario span{
    color: var(--primary);
}
h3.titulo-primario span {
    font-size: 2rem;
    margin-left: 75px;
}
/*h3.titulo-primario:before {
    content: "";
    display: block;
    width: 50px;
    height: 10px;
    background: var(--primary);
    top: 25%;
    position: absolute;
  }*/
h1.titulo-primario::after,
h2.titulo-primario::after,
h3.titulo-primario::after {
    font-weight: 400;
    position: absolute;
    content: attr(data-bg-text);
    font-size: 1.875rem;
    width: 100%;
    /*filter: blur(2px);*/
    color: var(--primary);
    text-transform: none;
    opacity: 0.25;
    top: 0;
    /*left: 146px;*/
    left: 50%;
    transform: translate(-50%, -125%);
}
#newsletter h3.titulo-primario span {
    color: var(--white);
    text-transform: uppercase;
}
#newsletter h3.titulo-primario:before {
    background: var(--white);
  }
#newsletter h1.titulo-primario::after,
#newsletter h2.titulo-primario::after,
#newsletter h3.titulo-primario::after {
    background: var(--white);
}
#newsletter p,
#newsletter span {
    color: var(--white);
}
.titulo-secundario{
    color: var(--primary);
    font-size: 1.5rem;
}
.subtitulo-primario, .subtitulo-secundario{
    color: var(--secondary);
    font-size: 1.125rem;
    line-height: 1.2;
    letter-spacing: 0.5px;
}
.subtitulo-secundario{
    color: var(--primary);
}

.vertical-title-left,
.vertical-title-right {
    display: none;
}

span.legenda {
    font-size: .75rem;
}

blockquote {
    border-left: 5px solid var(--red);
    font-size: inherit;
    background-color: #f6f6f6;
    margin: 20px 0 20px 20px;
    padding: 1rem 1rem 0.5rem;
}
.limit-5-lines{
    line-clamp: 5;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.limit-10-lines{
    line-clamp: 10;
    display: -webkit-box;
    -webkit-line-clamp: 10;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.column-count-2{
    column-count: 2;
}
@media (max-width: 576px) {
    .column-count-2{
        column-count: 1;
    }
}

.bg-color-gray-light{
    background-color: var(--gray-light);
}
.bg-color-gray{
    background-color: var(--gray);
}
.bg-color-red{
    background-color: var(--red);
}
.bg-color-orange{
    background-color: var(--orange);
}
.bg-color-blue{
    background-color: var(--blue);
}
.bg-color-body{
    background-color: var(--body);
}
.bg-color-white{
    background-color: var(--white);
}
.bg-color-black{
    background-color: var(--black);
}
.bg-primary{
    background-color: var(--primary) !important;
}
.bg-secondary{
    background-color: var(--secondary) !important;
}
.bg-navbar{
    background-color: var(--white);
}
.bg-copyright {
    background-color: color-mix(in srgb, var(--secondary), white 5%);
}

.parallax{
    position: relative;
    height: auto;

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;
}

@supports (-webkit-overflow-scrolling: touch) {
    /* CSS specific to iOS devices */
    .parallax{
        background-attachment: scroll;
    }

}
  
@supports not (-webkit-overflow-scrolling: touch) {
    /* CSS for other than iOS devices */
}

.background-image{
    position: relative;
    height: 250px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.overlay-img {
    /*background: rgba(211, 215, 221, 0.6);*/
    /*background: rgb(140 111 41 / 75%);*/
    background: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100%;
}

.loader {
    margin: 0;
    position: fixed;
    display: block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    
}

.div-component a {
    text-decoration: none;
    /*color: var(--body)*/
}

/* TESTEMUNHOS/OPINIÃO */

    #opinion .testimonial-text,
    #opinion .testimonial-text p {
        font-size: 1rem;
        font-weight: 500;
        line-height: initial;
        color: var(--white);

    }

    #opinion h3.titulo-primario::after {
        color: var(--white);
    }

    #opinion .testimonial-title::before {
        height: 1px;
        width: 4rem;
        content: "";
        display: block;
        background-color: var(--white);
        margin: 1rem 0;
        text-align: left;
    }

/* END TESTEMUNHOS/OPINIÃO */

/* SWIPER CAROUSEL WELCOME */

    #homenews_01 .swiper-pagination-bullet,
    #homebrands_01 .swiper-pagination-bullet,
    #homebrands_04 .swiper-pagination-bullet,
    #homepartners_02 .swiper-pagination-bullet {
        background: var(--secondary) !important;
    }

    .swiper-pagination-noticias,
    .swiper-pagination-brands,
    .swiper-pagination-partners,
    .swiper-pagination-testemunhos {
        position: relative;
        text-align: center;
        transition: .3s opacity;
        transform: translate3d(0,0,0);
        z-index: 10;
        bottom: 0 !important;
    }

/* END SWIPER WELCOME */


/* SEARCH */
    .limit-lines {
        line-clamp: 3;
        -webkit-line-clamp: 3;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
/* end SEARCH */


/* PAGINATION */
    .pagination {
        margin: 15px auto;
        display: flex;
        list-style: none;
        outline: none;
    }
    .pagination > .active > a{
        background-color: var(--body);
        border-color: var(--body);
        color: #fff;
    }
    .pagination > li > a{
        /*border: 1px solid var(--body);*/
        padding: 5px 10px;
        margin: auto 5px;
        outline: none;
        cursor: pointer;
    }
    .pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus{
        background-color: var(--blue);
        border-color: var(--blue);
        outline: none;
    }
    .pagination > li > a, .pagination > li > span{
        color: var(--body);
    }
    .pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span{
        border-radius: unset;
    }
/* end PAGINATION */

/* COOKIES */
    .containerCookie {
        bottom: 0;
        left: 0;
        position: fixed;
        right: 0;
        z-index: 3;
        background: var(--secondary) !important;
        justify-content: center !important;
    }

    .messageCookie {
        margin: 0 0 10px;
        padding: 10px 20px;
        display: block;
        line-height: 1.2em;
        font-size: 0.85rem;
        /*text-align: justify;*/
        margin: 0;
        text-decoration: none;
        color: var(--white);
    }

    .messageCookie p {
        margin: 0;
    }

    .messageCookie a {
        color: var(--primary);
        text-decoration: none;
    }
    .messageCookie a:hover {
        text-decoration: underline;
    }

    .btn-cookies {
        background-color: var(--primary) !important;
        font-weight: 700;
        border: 1px solid var(--primary)  !important;
        color: var(--white) !important;
        border-radius: 0px !important;
    }

    .btn-cookies:hover, .btn-cookies:active, .btn-cookies.active, .open > .dropdown-toggle.btn-cookies,
    .btn-cookies:focus, .btn-cookies.focus{
        background-color: var(--white)  !important;
        border-color: var(--secondary)  !important;
        outline: none !important;
        color: var(--secondary) !important;
    }
/*end COOKIES */

/* CARDS */

    #programas .card {
        border: 1px solid rgba(0,0,0,.05);
        cursor: default;
    }
    #parcerias .card,
    #gallery-cards .card,
    #gallery .card {
        /*transition: .5s;
        border: unset;
        box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);
        cursor: default;
        border-radius: 0;*/

        border: 1px solid rgba(0,0,0,.05);
        box-shadow: 0 1px 2px rgba(0,0,0,0.06), 0 1px 2px rgba(0,0,0,0.08);
        transition: all 0.3s cubic-bezier(.25,.8,.25,1);
        cursor: default;
    }
    #parcerias .card:hover,
    #gallery-cards .card:hover,
    #gallery .card:hover {
        /*box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);*/
        box-shadow: 0 3px 6px rgba(0,0,0,0.06), 0 3px 6px rgba(0,0,0,0.08);
    }

/* END CARDS */

/* MODAL */
    /*.modal{
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0,0,0,.5);
        display: flex;
        align-items: center;
        justify-content: center;
    }*/
    .modal-title {
        font-weight: normal;
        font-size: 1.25rem;
    }
    .modal .close {
        /*font-size: 2rem;
		color: var(--gray-dark) !important;*/
        -webkit-transition: all 500ms linear;
		-ms-transition: all 500ms linear;
		transition: all 500ms linear;
    }

    .modal .close:hover,
    .modal .close:focus {
        color: var(--primary) !important;
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        transform: rotate(90deg);
    }
/* end MODAL */

/* PAGE 404 */

#notfound {
    position: relative;
    height: 100vh;
}

#notfound .notfound {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.notfound {
    max-width: 850px;
    width: 100%;
    line-height: 1.4;
    text-align: center;
    padding: 15px;
}

.notfound .notfound-404 {
    position: relative;
    height: 220px;
}

.notfound .notfound-404 h1 {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-size: 186px;
    font-weight: 400;
    margin: 0px;
    background: linear-gradient(130deg, var(--primary), var(--secondary));
    color:transparent;
    -webkit-background-clip: text;
    background-clip: text;
    text-transform: uppercase;
}

.notfound h2 {
    font-size: 33px;
    text-transform: uppercase;
    margin-top: 0px;
    margin-bottom: 25px;
    letter-spacing: 3px;
}


.notfound p {
    margin-top: 0px;
    margin-bottom: 25px;
}


.notfound a {
    color: var(--secondary);
    text-decoration: none;
    border-bottom: 1px dashed var(--secondary);
    border-radius: 2px;
}

.notfound-social>a {
    display: inline-block;
    height: 40px;
    line-height: 40px;
    width: 40px;
    font-size: 14px;
    color: var(--secondary);
    border: 1px solid #efefef;
    border-radius: 50%;
    margin: 3px;
    -webkit-transition: 0.2s all;
    transition: 0.2s all;
}
.notfound-social>a:hover {
    color: #fff;
    background-color: var(--secondary);
    border-color: var(--secondary);
}

/* END PAGE 404 */


/* PAGE MAINTENANCE */

#maintenance {
    position: relative;
    height: 100vh;
}

#maintenance .maintenance {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.maintenance {
    max-width: 850px;
    width: 100%;
    line-height: 1.4;
    text-align: center;
    padding: 15px;
}

.maintenance .maintenance-txt {
    position: relative;
    height: 220px;
}

.maintenance .maintenance-txt h1 {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-size: 55px;
    font-weight: 400;
    margin: 0px;
    background: linear-gradient(130deg, var(--primary), var(--secondary));
    color:transparent;
    -webkit-background-clip: text;
    background-clip: text;
    text-transform: uppercase;
}

.maintenance h2 {
    font-size: 33px;
    text-transform: uppercase;
    margin-top: 0px;
    margin-bottom: 25px;
    letter-spacing: 3px;
}


.maintenance p {
    margin-top: 0px;
    margin-bottom: 25px;
}


.maintenance a {
    color: var(--secondary);
    text-decoration: none;
    border-bottom: 1px dashed var(--secondary);
    border-radius: 2px;
}

.maintenance-social>a {
    display: inline-block;
    height: 40px;
    line-height: 40px;
    width: 40px;
    font-size: 14px;
    color: var(--secondary);
    border: 1px solid #efefef;
    border-radius: 50%;
    margin: 3px;
    -webkit-transition: 0.2s all;
    transition: 0.2s all;
}
.maintenance-social>a:hover {
    color: #fff;
    background-color: var(--secondary);
    border-color: var(--secondary);
}

/* END PAGE MAINTENANCE */

/*Scroll-top bottom*/
    .scroll-top-wrapper {
        position: fixed;
        opacity: 0;
        visibility: hidden;
        overflow: hidden;
        text-align: center;
        z-index: 998;
        color: rgb(255, 255, 255);
        background-color: var(--primary);
        border: 1px solid var(--primary);
        width: 50px;
        height: 50px;
        line-height: 50px;
        right: 20px;
        bottom: 30px;

        /* add a translate effect */
        transform: translateY(100px);

        /* add a transition */
        -webkit-transition: all 0.5s ease-in-out;
        -moz-transition: all 0.5s ease-in-out;
        -ms-transition: all 0.5s ease-in-out;
        -o-transition: all 0.5s ease-in-out;
        transition: all 0.5s ease-in-out;
    }
    .scroll-top-wrapper:hover {
        color: var(--primary);
        background-color: white;
        border: 1px solid var(--primary);
    }
    .scroll-top-wrapper.show {
        visibility: visible;
        cursor: pointer;
        opacity: 1.0;
        transform: translateY(0);
    }
    /*.scroll-top-wrapper i.fa {
        line-height: inherit;
    }*/
    /*.fa-2x {
        font-size: 1.5em;
    }*/
/*end scroll-top*/

/* Bootstrap icons */

    .bi-2x {
        font-size: 1.8rem;
    }

/* END Bootstrap icons */


/* QUILL EDITOR */

.ql-indent-1 {
    padding-left: 3em;
  }
li.ql-indent-1 {
    padding-left: 4.5em;
  }
.ql-indent-1 {
    padding-right: 3em;
  }
li.ql-indent-1 {
    padding-right: 4.5em;
  }
.ql-indent-2 {
    padding-left: 6em;
  }
li.ql-indent-2 {
    padding-left: 7.5em;
  }
.ql-indent-2 {
    padding-right: 6em;
  }
li.ql-indent-2 {
    padding-right: 7.5em;
  }
.ql-indent-3 {
    padding-left: 9em;
  }
li.ql-indent-3 {
    padding-left: 10.5em;
  }
.ql-indent-3 {
    padding-right: 9em;
  }
li.ql-indent-3 {
    padding-right: 10.5em;
  }
.ql-indent-4 {
    padding-left: 12em;
  }
li.ql-indent-4 {
    padding-left: 13.5em;
  }
.ql-indent-4 {
    padding-right: 12em;
  }
li.ql-indent-4 {
    padding-right: 13.5em;
  }
.ql-indent-5 {
    padding-left: 15em;
  }
li.ql-indent-5 {
    padding-left: 16.5em;
  }
.ql-indent-5 {
    padding-right: 15em;
  }
li.ql-indent-5 {
    padding-right: 16.5em;
  }
.ql-indent-6 {
    padding-left: 18em;
  }
li.ql-indent-6 {
    padding-left: 19.5em;
  }
.ql-indent-6 {
    padding-right: 18em;
  }
li.ql-indent-6 {
    padding-right: 19.5em;
  }
.ql-indent-7 {
    padding-left: 21em;
  }
li.ql-indent-7 {
    padding-left: 22.5em;
  }
.ql-indent-7 {
    padding-right: 21em;
  }
li.ql-indent-7 {
    padding-right: 22.5em;
  }
.ql-indent-8 {
    padding-left: 24em;
  }
li.ql-indent-8 {
    padding-left: 25.5em;
  }
.ql-indent-8 {
    padding-right: 24em;
  }
li.ql-indent-8 {
    padding-right: 25.5em;
  }
.ql-indent-9 {
    padding-left: 27em;
  }
li.ql-indent-9 {
    padding-left: 28.5em;
  }
.ql-indent-9 {
    padding-right: 27em;
  }
li.ql-indent-9 {
    padding-right: 28.5em;
  }
/* eND QUILL EDITOR */


.quantity-input {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .quantity-input__modifier,
  .quantity-input__screen {
    user-select: none;
    outline: none;
  }
  
  .quantity-input__modifier {
    padding: .7rem;
    width: 3rem;
    font-size: 1rem;
    
    background: #f3f3f3;
    color: #888;
    border: 0 solid #dbdbdb;
    text-align: center;
    text-shadow: 0 1px 0 rgba(#fff, .6);
    
    cursor: pointer;
  }
  
  .quantity-input__screen {
    width: 4rem;
    padding: .7rem;
    font-size: 1rem;
    
    border: 0;
    border-top: 0 solid #dbdbdb;
    border-bottom: 0 solid #dbdbdb;
    text-align: center;
  }


  .bg-restauracao {
    background-color: rgb(200, 99, 32);
  }
  .bg-alojamento {
    background-color: rgb(94, 52, 38);
  }
  .bg-eventos {
    background-color: rgb(111, 84, 170);
  }
  .bg-novidades {
    background-color: rgb(158, 200, 58);
  }
  .bg-animacao {
    background-color: rgb(234, 180, 46);
  }
  .bg-rotas {
    background-color: rgb(64, 141, 212);
  }

@media only screen and (max-width: 480px) {
    .notfound .notfound-404 {
        position: relative;
        height: 168px;
    }

    .notfound .notfound-404 h1 {
        font-size: 142px;
    }

    .notfound h2 {
        font-size: 22px;
    }
}


/*@media screen and (min-width: 1600px) {
    .vertical-title-left {
        display: block;
        font-weight: 700;
        color: rgba(131,161,178,0.3);
        opacity: 0.5;
        font-size: 80px;
        line-height: 60px;
        white-space: nowrap;
        position: absolute;
        top: 10%;
        right: 115%;
        transform-origin: top right 0;
        transform: rotate( 270deg );
    }
    
    .vertical-title-right {
        display: block;
        font-weight: 700;
        color: rgba(131,161,178,0.3);
        opacity: 0.5;
        font-size: 80px;
        line-height: 60px;
        white-space: nowrap;
        position: absolute;
        top: 10%;
        right: -8%;
        transform-origin: top right 0;
        transform: rotate( 270deg );
    }
}*/



